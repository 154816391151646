// import React, { useState, useContext, useCallback, useEffect } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import {
	Button,
	FormControl,
	GridItem,
	Input,
	Flex,
	Grid,
} from "@chakra-ui/react";
import Card from "../../../components/Footer/Card/Card.js";
import CardBody from "../../../components/Footer/Card/CardBody.js";
import { fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import { AddReferrersAPI } from "../../../Endpoints.jsx";
import { useContext, useState } from "react";

const inputStyles = { opacity: 1, cursor: "text" };

const AddReferrer = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [referrer, setReferrer] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		account_name: "",
		account_number: "",
		bank_name: "",
	});
	const handleChange = (prop) => (e) => {
		const { value } = e.target;
		setReferrer((prev) => ({ ...prev, [prop]: value }));
	};

	const notify = useNotifier();

	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Success", _res?.message, "success");

			setReferrer({
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				account_name: "",
				account_number: "",
				bank_name: "",
			});
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: AddReferrersAPI,
			method: "POST",
			data: referrer,
			token,
			handleSuccess,
			handleError,
		});
	};

	return (
		<Card p="16px" w="100%" pt={{ base: "120px", md: "75px" }}>
			<CardBody>
				<form onSubmit={onSubmit}>
					<Grid templateColumns="repeat(3, 1fr)" gap={6}>
						<GridItem>
							<label>First Name</label>
							<Input
								size="md"
								value={referrer.first_name}
								style={inputStyles}
								onChange={handleChange("first_name")}
							/>
						</GridItem>

						<GridItem>
							<label>Last Name</label>
							<Input
								size="md"
								value={referrer.last_name}
								style={inputStyles}
								onChange={handleChange("last_name")}
							/>
						</GridItem>

						<GridItem>
							<label>Email</label>
							<Input
								size="md"
								value={referrer.email}
								style={inputStyles}
								onChange={handleChange("email")}
							/>
						</GridItem>

						<GridItem>
							<label>Phone</label>
							<Input
								size="md"
								value={referrer.phone}
								style={inputStyles}
								onChange={handleChange("phone")}
							/>
						</GridItem>

						<GridItem>
							<label>Account Name</label>
							<Input
								size="md"
								value={referrer.account_name}
								style={inputStyles}
								onChange={handleChange("account_name")}
							/>
						</GridItem>
						<GridItem>
							<label>Account Number</label>
							<Input
								size="md"
								value={referrer.account_number}
								style={inputStyles}
								onChange={handleChange("account_number")}
							/>
						</GridItem>
						<GridItem>
							<label>Bank Name </label>
							<Input
								size="md"
								value={referrer.bank_name}
								style={inputStyles}
								onChange={handleChange("bank_name")}
							/>
						</GridItem>
					</Grid>
					<FormControl>
						<Button
							type="submit"
							isLoading={loading}
							fontSize="12px"
							fontWeight="medium"
							borderRadius={"100px"}
							px={6}
							w="max"
							mt={4}
							bg="#de9301"
							_hover="none"
							_active={{
								bg: "white",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
							color="white"
						>
							Add Referrer
						</Button>
					</FormControl> 
				</form>
			</CardBody>
		</Card>
	);
};

export default AddReferrer;
