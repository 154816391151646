import {
	Button,
	Flex,
	FormControl,
	Input,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useDisclosure,
} from "@chakra-ui/react";
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import GlobalContext from "../../../Context";

import {
	AdminChangeLogoAPI,
	AdminGetSettingsAPI,
	AdminPaymentSettingsAPI,
	AdminWebContentAPI,
} from "../../../Endpoints";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchAPI, postAuth, putAuth } from "../../../utils/fetchAPI";
import { isInValid } from "../../../utils/index.js";

const Index = () => {
	const { token } = useContext(GlobalContext);

	const [submitting, setSubmitting] = useState(false);
	const [submittingPayment, setSubmittingPayment] = useState(false);
	const [submittingLogo, setSubmittingLogo] = useState(false);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [logo, setLogo] = useState("");
	const [Dlogo, setDLogo] = useState("");
	const [newLogo, setNewLogo] = useState("");
	const [address, setAddress] = useState("");
	const [desc, setDesc] = useState("");
	const [privacy, setPrivacy] = useState("");
	const [terms, setTerms] = useState("");

	const [secret, setSecret] = useState("");
	const [publicK, setPublic] = useState("");
	const [currency, setCurrency] = useState("");

	const termsRef = useRef(null);
	const privacyRef = useRef(null);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const notify = useNotifier();

	const saveContent = (e) => {
		e.preventDefault();

		if (
			isInValid(termsRef.current.getContent()) ||
			isInValid(privacyRef.current.getContent()) ||
			isInValid(name) ||
			isInValid(email) ||
			isInValid(phone) ||
			isInValid(address) ||
			isInValid(desc)
		) {
			return notify("All form fields are required", "", "error");
		}

		setSubmitting(true);

		const handleSaveSuccess = (_res) => {
			setSubmitting(false);

			if (_res?.status === "success") {
				notify("Web Content saved successfully", "", "success");
			} else {
				let errors = _res?.errors;

				if (errors && Object.keys(errors).length > 0) {
					for (let err in errors) {
						notify(errors[`${err}`][0], "", "error");
					}
				} else {
					notify("Failed", _res?.message, "error");
				}
			}
		};

		const handleSaveError = (_err) => {
			setSubmitting(false);

			let errors = _err?.errors;

			if (errors && Object.keys(errors).length > 0) {
				for (let err in errors) {
					notify(errors[`${err}`][0], "", "error");
				}
			} else {
				notify("Failed", _err?.message, "error");
			}
		};

		const requestBody = {
			name,
			email,
			phone,
			address,
			description: desc,
			terms: termsRef.current.getContent(),
			privacy: privacyRef.current.getContent(),
		};

		putAuth(
			AdminWebContentAPI,
			requestBody,
			handleSaveSuccess,
			handleSaveError,
			token
		);
	};

	const savePaymentSettings = (e) => {
		e.preventDefault();

		if (isInValid(secret) || isInValid(publicK) || isInValid(currency)) {
			return notify("All form fields are required", "", "error");
		}

		setSubmittingPayment(true);

		const handleSaveSuccess = (_res) => {
			setSubmittingPayment(false);

			if (_res?.status === "success") {
				notify("Payment Settings saved successfully", "", "success");
			} else {
				let errors = _res?.errors;

				if (errors && Object.keys(errors).length > 0) {
					for (let err in errors) {
						notify(errors[`${err}`][0], "", "error");
					}
				} else {
					notify("Failed", _res?.message, "error");
				}
			}
		};

		const handleSaveError = (_err) => {
			setSubmittingPayment(false);

			let errors = _err?.errors;

			if (errors && Object.keys(errors).length > 0) {
				for (let err in errors) {
					notify(errors[`${err}`][0], "", "error");
				}
			} else {
				notify("Failed", _err?.message, "error");
			}
		};

		const requestBody = {
			currency,
			paystack_secret: secret,
			paystack_public: publicK,
		};

		putAuth(
			AdminPaymentSettingsAPI,
			requestBody,
			handleSaveSuccess,
			handleSaveError,
			token
		);
	};

	const changeLogo = (e) => {
		const image = e?.target?.files[0];

		if (image) {
			setLogo(image);

			const reader = new FileReader();

			reader.addEventListener("load", () => setNewLogo(reader.result));
			reader.readAsDataURL(image);

			onOpen();
		}
	};

	const saveLogo = (e) => {
		e.preventDefault();

		if (typeof logo === "string") {
			return notify("Kindly select an image", "", "error");
		}

		setSubmittingLogo(true);

		const handleSaveSuccess = (_res) => {
			setSubmittingLogo(false);

			if (_res?.status === "success") {
				fetchData();
				onClose();
				notify("Application logo saved successfully", "", "success");
			} else {
				let errors = _res?.errors;

				if (errors && Object.keys(errors).length > 0) {
					for (let err in errors) {
						notify(errors[`${err}`][0], "", "error");
					}
				} else {
					notify("Failed", _res?.message, "error");
				}
			}
		};

		const handleSaveError = (_err) => {
			setSubmittingLogo(false);

			let errors = _err?.errors;

			if (errors && Object.keys(errors).length > 0) {
				for (let err in errors) {
					notify(errors[`${err}`][0], "", "error");
				}
			} else {
				notify("Failed", _err?.message, "error");
			}
		};

		const requestBody = new FormData();
		requestBody.append("logo", logo);

		const requestOptions = {
			method: "POST",
			headers: new Headers({
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			}),
			body: requestBody,
		};

		postAuth(
			AdminChangeLogoAPI,
			requestBody,
			handleSaveSuccess,
			handleSaveError,
			token,
			requestOptions
		);
	};

	const fetchData = useCallback(() => {
		const handleSuccess = (_res) => {
			if (_res?.status === "success") {
				const {
					name,
					fullPath,
					email,
					description,
					address,
					phone,
					privacy,
					terms,
					currency,
					paystack_public,
					paystack_secret,
				} = _res?.app_settings;

				setName(name);
				setEmail(email);
				setPhone(phone);
				setLogo(fullPath);
				setDLogo(fullPath);
				setAddress(address);
				setDesc(description);
				setPrivacy(privacy);
				setTerms(terms);

				setSecret(paystack_secret);
				setPublic(paystack_public);
				setCurrency(currency);
			} else {
				notify("Failed", "Could not get app settings", "error");
			}
		};

		const handleError = (_err) => {
			notify("Failed", "Could not get app settings", "error");
		};

		fetchAPI(AdminGetSettingsAPI, handleSuccess, handleError, token);
		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			<Tabs isFitted variant="enclosed">
				<TabList mb="1em">
					<Tab>Update Profile</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<form onSubmit={saveContent}>
							<Stack spacing={8}>
								{/* name */}
								<Stack spacing={3}>
									<label>Name</label>
									<Input
										placeholder="Name"
										size="md"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										disabled={submitting}
										required
									/>
								</Stack>

								{/* email */}
								<Stack spacing={3}>
									<label>Email</label>
									<Input
										type="email"
										placeholder="Email"
										size="md"
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										disabled={submitting}
										required
									/>
								</Stack>

								{/* Phone */}
								<Stack spacing={3}>
									<label>Phone</label>
									<Input
										placeholder="Phone"
										size="md"
										value={phone}
										onChange={(e) =>
											setPhone(e.target.value)
										}
										disabled={submitting}
										required
									/>
								</Stack>

								{/* email */}
								<Stack spacing={3}>
									<label>Adresss</label>
									<Input
										placeholder="Address"
										size="md"
										value={address}
										onChange={(e) =>
											setAddress(e.target.value)
										}
										disabled={submitting}
										required
									/>
								</Stack>

								<FormControl>
									<Button
										type="submit"
										isLoading={submitting}
										disabled={submitting}
										p={"8px 40px"}
										borderRadius={"5px"}
										colorScheme="yellow"
										// variant={"solid"}
										mb="10"
										color="white"
									>
										Update
									</Button>
								</FormControl>
							</Stack>
						</form>
						<hr></hr>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	);
};

export default Index;
