import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
// Chakra imports
import {
	Button,
	Flex,
	IconButton,
	SimpleGrid,
	Spinner,
	Stack,
	Tag,
	Text,
	Tooltip,
	useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { AiFillRead } from "react-icons/ai";
import { FaEye } from "react-icons/fa6";
import { LuRefreshCcw } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { RiUserVoiceLine } from "react-icons/ri";
import { TfiWrite } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context.jsx";
import { ApartmentsAPI, deleteApartmentsAPI } from "../../../Endpoints.jsx";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchAPI, fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import { numberWithCommas } from "../../../utils/index.js";
import MiniStatistics from "../Landlord/components/MiniStatistics.js";

const Index = () => {
	const iconBoxInside = useColorModeValue("#fccb08", "white");
	const { token } = useContext(GlobalContext);
	const [statusLoading, setStatusLoading] = useState(false);

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [totalapartments, setTotalApartments] = useState(0);
	const [totalbooked, setTotalBooked] = useState(0);
	const [totalavailable, setTotalAvailable] = useState(0);
	const [apartments, setApartments] = useState([]);

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			// if (_res && _res?.status === "success") {
			if (_res?.status === "success") {
				setTotalApartments(Number(_res.total_apartments));
				setTotalBooked(Number(_res.total_booked));
				setTotalAvailable(Number(_res.total_available));

				setApartments(_res?.apartments);
			} else {
				notify("Failed", "Could not get data", "error");
			}

			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get data", "error");
		};

		fetchAPI(ApartmentsAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const deleteApartmentHandler = async (id) => {
		setDeleteLoading(true);

		const handleSuccess = (res) => {
			setDeleteLoading(false);
			notify("Success", res.message, "success");
			fetchData();
		};
		const handleError = (err) => {
			console.log(err, "err");
			setDeleteLoading(false);
			notify("Failed", err.message, "error");
		};
		await fetchProtectedResource({
			url: deleteApartmentsAPI + id,
			token,
			handleSuccess,
			handleError,
		});
		setDeleteLoading(false);
	};
	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Fragment>
					<SimpleGrid
						columns={{ sm: 1, md: 2, xl: 3 }}
						spacing="24px"
					>
						<MiniStatistics
							title={"Total no. of Apartment"}
							amount={numberWithCommas(totalapartments)}
							icon={TfiWrite}
						/>
						<MiniStatistics
							title={"Total Booked"}
							amount={numberWithCommas(totalbooked)}
							icon={RiUserVoiceLine}
						/>
						<MiniStatistics
							title={"Total no. available"}
							amount={numberWithCommas(totalavailable)}
							icon={AiFillRead}
						/>
					</SimpleGrid>

					<Card p="32px" mt="20px" w="100%">
						<CardHeader>
							<Flex
								justify="space-between"
								align="center"
								minHeight="60px"
								w="100%"
							>
								<Stack direction="row">
									<Text fontSize="lg" fontWeight="bold">
										Apartments
									</Text>
									<Tooltip label="Refresh">
										<IconButton
											onClick={() => fetchData()}
											aria-label="Refresh"
											colorScheme="inherits"
											icon={
												<LuRefreshCcw color="black" />
											}
										/>
									</Tooltip>
								</Stack>

								<Link to="/admin/apartments/new">
									<Button
										p={"8px 30px"}
										borderRadius={"5px"}
										variant={"solid"}
										color="#de9301"
										fontWeight="bold"
									>
										Add Apartment
									</Button>
								</Link>
							</Flex>
						</CardHeader>
						<CardBody
							display={"block"}
							style={{ flexDirection: "row" }}
						>
							<DataTable
								value={apartments}
								paginator
								rows={5}
								rowsPerPageOptions={[5, 10, 25, 50]}
							>
								{/* <Column
									sortable
									field="id"
									filter
									header="#"
									body={(row, index) =>index  }
									// style={{ width: "25%" }}
								></Column> */}
								<Column
									sortable
									field="landlord_name"
									filter
									header="Landlord Name"
									body={(row) =>
										row?.owner.first_name +
										" " +
										row?.owner.last_name
									}
									// style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="email"
									filter
									header="Email"
									body={(row) => row?.owner.email ?? "N/A"}
									// style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="apartments"
									filter
									header="Apartment"
									body={(row) => row?.name ?? "N/A"}
									// style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field="phone"
									filter
									header="Phone"
									body={(row) => row?.owner.phone}
									// style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="other_phone"
									filter
									header="Other Phone"
									body={(row) => row?.owner.other_phone}
									// style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="created_at"
									filter
									header="Created"
									body={(row) => (
										<Text>
											{dayjs(row?.created_at).format(
												"ddd D MMM YYYY"
											)}
										</Text>
									)}
									// style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field="is_booked"
									filter
									header="Booked"
									body={(row) =>
										row?.is_booked === "0" ? "No" : "Yes"
									}
									// style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="status"
									filter
									header="Status"
									body={(row) => (
										<Tag
											borderRadius="full"
											style={{
												textTransform: "capitalize",
											}}
										>
											{row.status}
										</Tag>
									)}
									// style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field=""
									header="Action"
									body={(row) => (
										<Stack direction="row" spacing={2}>
											<IconButton
												aria-label="View"
												size="sm"
												icon={<FaEye color="black" />}
												onClick={() =>
													navigate(
														`/admin/apartments/${row?.id}`
													)
												}
											/>
											<IconButton
												aria-label="Delete"
												size="sm"
												icon={
													<MdDelete color="firebrick" />
												}
												onClick={() =>
													deleteApartmentHandler(
														row?.id
													)
												}
												disabled={deleteLoading}
											/>
										</Stack>
									)}
									// style={{ width: "25%" }}
								></Column>

								{/* <Column
									sortable
									field=""
									header="Sold out"
									body={(row) => (
										<Switch
											disabled={statusLoading}
											onChange={() => {
												console.log(row, "row");
												// toggleStatus(row?.id);
											}}
											isChecked={
												row.status === "active"
													? true
													: false
											}
										/>
									)}
									// style={{ width: "25%" }}
								></Column> */}
							</DataTable>
						</CardBody>
					</Card>
				</Fragment>
			)}
		</Flex>
	);
};

export default Index;
