import React, { useState, useContext, useCallback, useEffect } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { Button, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import { fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import { ViewRequestsDetailsAPI, ManualAPI } from "../../../Endpoints.jsx";
import { useParams } from "react-router-dom";
import { numberWithCommas } from "../../../utils/index.js";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { BsEyeFill } from "react-icons/bs";


const ManualReservationDetails = () => {
	const { id } = useParams();

	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	const [reservation, setReservation] = useState({});
	const [open, setOpen] = React.useState(false);
	const [ receiptUrl, setReceiptUrl ] = useState('');

	const notify = useNotifier();

	const approveRequest = () => {
		setLoading(true);

		fetch(`${ManualAPI}${id}`, {
			method: "GET",
			headers: new Headers({
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			})
		})
		.then((res) => res.json())
		.then((resp) => {
			setLoading(false);

			if (resp.status !== "success") {
				notify("Failed", "Invalid reservation request sent", 'error');
			}

			notify(resp.message);
			getReservationRequestDetails();
		})
		.catch((error) => {
			setLoading(false);

			console.log(error);
			notify('Failed', "Try again", 'error');
		});
	};

	const getReservationRequestDetails = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Done", _res?.message, "success");
			const { request } = _res;

			setReservation({
				name: request.apartment.name,
				check_in: request.check_in,
				check_out: request.check_out,

				landlord_fee: request.apartment.landlord_fee,
				africartz_fee: request.apartment.africartz_fee,
				caution_fee: request.apartment.caution_fee,

				first_name: request.user.first_name,
				last_name: request.user.last_name,
				phone: request.user.phone,
				first: request.landlord.first_name,
				phoneno: request.landlord.phone,
				status: request.status,
			});

			setReceiptUrl(request?.fullReceiptPath);

			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: ViewRequestsDetailsAPI + id,
			method: "GET",
			token,
			handleSuccess,
			handleError,
		});
	}, []);

	useEffect(() => {
		getReservationRequestDetails();
	}, []);

	return (
		<Flex flexDirection={"row"} pt={{ base: "120px", md: "75px" }}>
			<Card p="16px" w={{ md: "100%", sm: "100%" }}>
				<CardBody display={"block"}>

					<Flex flexDirection={{ base: 'column' }}>
						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>{" "}Apartment</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Name:{reservation.name}
							</Text>
							<Text fontSize="lg" fontWeight={"semibold"}>Reservation Status: {reservation?.status}</Text>
						</Stack>

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Reservation Dates
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Check In:{reservation.check_in}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Check Out:{reservation.check_out}
							</Text>
						</Stack>

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Super Agent
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Super Agent Name:{reservation.first}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Super Phone No:{reservation.phone}
							</Text>
						</Stack>

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Customer
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Customer Name:{reservation.first_name} &nbsp;{" "}
								{reservation.last_name}
							</Text>

							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Customer Phone No:{reservation.phone}
							</Text>
						</Stack>

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Cost
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Landlord fee: ₦{numberWithCommas(reservation.landlord_fee)}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Africartz fee: ₦{numberWithCommas(reservation.africartz_fee)}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Caution fee: ₦{numberWithCommas(reservation.caution_fee)}
							</Text>
							<Text fontSize="xl" fontWeight={"light"}>
								Total:
							</Text>
						</Stack>

						<Flex
							gap={"4"}
							justifyItems={"center"}
							alignContent={"center"}
						>
							{reservation?.status === 'manual' && (
								<Button
									onClick={approveRequest}
									type="submit"
									isLoading={loading}
									fontSize="12px"
									fontWeight="medium"
									borderRadius={"100px"}
									px={6}
									w="max"
									mt={4}
									bgColor="#fbca07"
									_hover="none"
									_active={{
										bg: "white",
										transform: "none",
										borderColor: "transparent",
									}}
									_focus={{
										boxShadow: "none",
									}}
									color="white"
								>
									Approve &nbsp; <Icon as={CheckCircleIcon} />
								</Button>
							)}

							<Button
								onClick={() => setOpen(true)}
								type="button"
								fontSize="12px"
								fontWeight="medium"
								borderRadius={"100px"}
								px={6}
								w="max"
								mt={4}
								colorScheme="orange"
								_hover="none"
								_active={{
									bg: "white",
									transform: "none",
									borderColor: "transparent",
								}}
								_focus={{
									boxShadow: "none",
								}}
								color="white"
							>
								View Receipt &nbsp; <Icon as={BsEyeFill} />
							</Button>

							<Lightbox
								open={open}
								close={() => setOpen(false)}
								slides={[
									{ src: receiptUrl }
								]}
								plugins={[Download, Zoom]}
							/>
						</Flex>
					</Flex>

				</CardBody>
			</Card>
		</Flex>
	);
};

export default ManualReservationDetails;
