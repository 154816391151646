import React, { useState, useContext, useCallback, useEffect } from "react";
import {
	Button,
	FormControl,
	Grid,
	GridItem,
	Input,
	Textarea,
} from "@chakra-ui/react";
import GlobalContext from "../../../Context.jsx";
import { AddApartmentsAPI, LandlordsAPI } from "../../../Endpoints.jsx";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchProtectedResource, postAuth } from "../../../utils/fetchAPI.jsx";
import SelectDropdown from "./SelectDropdown.jsx";
import ToggleInput from "../../../components/ToggleInput.jsx";
import AmountInput from "../../../components/AmountInput.jsx";
import IntegerInput from "../../../components/IntegerInput.jsx";
import { isInValid } from "../../../utils/index.js";
import { useNavigate } from "react-router-dom";
import { getAfricartzFee } from "./ApartmentDetails.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//

const inputStyles = { opacity: 1, cursor: "text" };

const AddApartments = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [name, setname] = useState();
	const [landlord, setlandlord] = useState();
	const [state, setstate] = useState();
	const [address, setaddress] = useState();
	const [location, setlocation] = useState();
	const [num_of_guests, setnum_of_guests] = useState("");
	const [num_of_bedrooms, setnum_of_bedrooms] = useState("");
	const [num_of_bathrooms, setnum_of_bathrooms] = useState("");
	const [num_of_beds, setnum_of_beds] = useState("");
	const [africartz_fee, setafricartz_fee] = useState(12000);
	const [landlord_fee, setlandlord_fee] = useState("");
	const [caution_fee, setcaution_fee] = useState("");

	const [photo_shoot_fee, setphoto_shoot_fee] = useState();
	const [photo_shoot_fee_visible, setphoto_shoot_fee_visible] =
		useState(false);

	const [movie_shoot_fee, setmovie_shoot_fee] = useState();
	const [movie_shoot_fee_visible, setmovie_shoot_fee_visible] =
		useState(false);

	const [birthday_fee, setbirthday_fee] = useState();
	const [birthday_fee_visible, setbirthday_fee_visible] = useState(false);

	const [owner_id, setowner_id] = useState();
	// const [available_dates, setavailable_dates] = useState();
	const [amenities, setamenities] = useState();
	const [description, setDescription] = useState("");

	const [selectedDate, setSelectedDate] = useState(new Date());

	const stateOptions = [
		{ value: "Lagos", label: "Lagos" },
		{ value: "Abuja", label: "Abuja" },
	];

	const [landlordOptions, setLandlordOptions] = useState([]);

	const amenitiesOptions = [
		{ value: "24/7 power supply", label: "24/7 power supply" },
		{ value: "Fitted Kitchen", label: "Fitted Kitchen" },
		{ value: "House keeping service", label: "House keeping service" },
		{ value: "Gated & secured estate", label: "Gated & secured estate" },
		{ value: "24/7 security guards", label: "24/7 security guards" },
		{ value: "Parking slot", label: "Parking slot" },
		{ value: "DSTV", label: "DSTV" },
		{ value: "Internet", label: "Internet" },
		{ value: "Netflix", label: "Netflix" },
		{
			value: "Children recreational area",
			label: "Children recreational area",
		},
		{ value: "Serene environment", label: "Serene environment" },
		{ value: "Ps4", label: "Ps4" },
		{ value: "Ps5", label: "Ps5" },
		{ value: "Snooker board", label: "Snooker board" },
		{ value: "Gym", label: "Gym" },
		{ value: "Pool", label: "Pool" },
		{ value: "En-suite rooms", label: "En-suite rooms" },
	];

	const notify = useNotifier();
	const navigate = useNavigate();

	const validateForm = () => {
		let requiredFields = [
			name,
			landlord,
			state,
			address,
			location,
			num_of_guests,
			num_of_bedrooms,
			num_of_bathrooms,
			num_of_beds,
			africartz_fee,
			landlord_fee,
			caution_fee,
			description,
		];

		for (let field of requiredFields) {
			if (isInValid(field)) {
				notify("", "All input fields are required", "error");
				return false;
			}
		}

		return true;
	};

	useEffect(() => {
		const fetchLandlordList = async () => {
			setLoading(true);

			const handleSuccess = (_res) => {
				notify("Success", _res?.message, "success");
				const landlordList = _res.landlords.map((item) => ({
					label:
						item.first_name.charAt(0).toUpperCase() +
						item.first_name.slice(1) +
						" " +
						item.last_name.charAt(0).toUpperCase() +
						item.last_name.slice(1),
					value: String(item.id),
				}));
				setLandlordOptions(landlordList);
				setLoading(false);
			};

			const handleError = (err) => {
				setLoading(false);
				notify("Error", err.message, "error");
			};
			fetchProtectedResource({
				url: LandlordsAPI,
				token,
				handleSuccess,
				handleError,
			});
		};
		fetchLandlordList();
	}, []);

	useEffect(() => {
		let af_fee = getAfricartzFee(landlord_fee);
		setafricartz_fee(af_fee);
	}, [landlord_fee]);

	const onSubmit = (e) => {
		e.preventDefault();

		// if(!validateForm()) return;

		setLoading(true);

		const handleSuccess = (_res) => {
			setLoading(false);

			if (_res?.status === "success") {
				notify("Success", _res?.message, "success");
				navigate(-1);
			} else {
				notify("Failed", _res?.message, "error");
			}
		};

		const handleError = (err) => {
			setLoading(false);
			notify("Error", err.message, "error");
		};

		let am = [];

		amenities.forEach((x) => {
			am.push(x?.value);
		});

		const requestBody = {
			name,
			address,
			amenities: am.join(","),
			num_of_guests,
			num_of_bedrooms,
			num_of_bathrooms,
			num_of_beds,
			owner_id: landlord?.value,
			africartz_fee,
			landlord_fee,
			caution_fee,
			// available_dates,
			location,
			state: state.value,
			photo_shoot_fee: photo_shoot_fee_visible ? photo_shoot_fee : null,
			movie_shoot_fee: movie_shoot_fee_visible ? movie_shoot_fee : null,
			birthday_fee: birthday_fee_visible ? birthday_fee : null,
			description,
		};

		postAuth(
			AddApartmentsAPI,
			requestBody,
			handleSuccess,
			handleError,
			token
		);
	};

	const CustomInput = ({ value, onClick }) => (
		<Input onClick={onClick} value={value} style={inputStyles} readOnly />
	);

	return (
		<Card p="16px" w="100%" pt={{ base: "120px", md: "75px" }}>
			<CardBody>
				<form onSubmit={onSubmit}>
					<Grid
						templateColumns={{
							base: "repeat(1, 1fr)",
							md: "repeat(2, 1fr)",
							lg: "repeat(4, 1fr)",
						}}
						gap={6}
					>
						<GridItem>
							<label>Name</label>
							<Input
								size="md"
								value={name}
								style={inputStyles}
								onChange={(x) => setname(x.target.value)}
							/>
						</GridItem>

						<GridItem>
							<label>Select Landlord</label>
							<SelectDropdown
								value={landlord}
								handleSelect={(value) => setlandlord(value)}
								options={landlordOptions}
								isMulti={false}
								disabled={loading}
							/>
						</GridItem>

						{/* <GridItem>
							<label>Available Dates</label>
							<DatePicker
								selected={selectedDate}
								onChange={(date) => setSelectedDate(date)}
								dateFormat="dd/MM/yyyy"
								minDate={new Date()}
								showMonthYearDropdown
								scrollableYearDropdown
								customInput={<CustomInput />}
							/>
						</GridItem> */}

						{/* */}
						{/*  */}
						<GridItem>
							<label>Amenities</label>
							<SelectDropdown
								value={amenities}
								handleSelect={(value) => setamenities(value)}
								options={amenitiesOptions}
								isMulti={true}
							/>
						</GridItem>

						<GridItem>
							<label>No. of Guest</label>
							<IntegerInput
								value={num_of_guests}
								setValue={setnum_of_guests}
							/>
						</GridItem>
						<GridItem>
							<label>No. of Bedrooms</label>
							<IntegerInput
								value={num_of_bedrooms}
								setValue={setnum_of_bedrooms}
							/>
						</GridItem>
						<GridItem>
							<label>No. of Bathrooms</label>
							<IntegerInput
								value={num_of_bathrooms}
								setValue={setnum_of_bathrooms}
							/>
						</GridItem>
						<GridItem>
							<label>No. of Beds</label>
							<IntegerInput
								value={num_of_beds}
								setValue={setnum_of_beds}
							/>
						</GridItem>

						<GridItem>
							<label>Default stay fee</label>
							<AmountInput
								value={landlord_fee}
								setValue={setlandlord_fee}
							/>
						</GridItem>
						<GridItem>
							<label>Africartz Fee</label>
							<AmountInput
								value={africartz_fee}
								setValue={setafricartz_fee}
								disabled
							/>
						</GridItem>
						<GridItem>
							<label>Caution fee</label>
							<AmountInput
								value={caution_fee}
								setValue={setcaution_fee}
							/>
						</GridItem>


						<GridItem>
							<label>Address</label>
							<Input
								size="md"
								value={address}
								style={inputStyles}
								onChange={(x) => setaddress(x?.target?.value)}
							/>
						</GridItem>
						<GridItem>
							<label>Location</label>
							<Input
								size="md"
								value={location}
								style={inputStyles}
								onChange={(x) => setlocation(x?.target?.value)}
							/>
						</GridItem>
						<GridItem>
							<label>State</label>
							<SelectDropdown
								value={state}
								handleSelect={(value) => setstate(value)}
								options={stateOptions}
								isMulti={false}
							/>
						</GridItem>


						<GridItem>
							<label htmlFor="">Description</label>

							<Textarea
								value={description}
								onChange={(x) => setDescription(x.target.value)}
								placeholder=""
								size="sm"
							/>
						</GridItem>
						<GridItem>
							<ToggleInput
								label="Photo shoot fee"
								isVisible={photo_shoot_fee_visible}
								setVisible={setphoto_shoot_fee_visible}
								value={photo_shoot_fee}
								handleChange={setphoto_shoot_fee}
							/>
							<ToggleInput
								label="Movie shoot fee"
								isVisible={movie_shoot_fee_visible}
								setVisible={setmovie_shoot_fee_visible}
								value={movie_shoot_fee}
								handleChange={setmovie_shoot_fee}
							/>
							<ToggleInput
								label="Party fee"
								isVisible={birthday_fee_visible}
								setVisible={setbirthday_fee_visible}
								value={birthday_fee}
								handleChange={setbirthday_fee}
							/>
						</GridItem>
					</Grid>

					<FormControl>
						<Button
							type="submit"
							isLoading={loading}
							fontSize="12px"
							fontWeight="medium"
							borderRadius={"100px"}
							px={6}
							w="max"
							mt={8}
							bg="#de9301"
							_hover="none"
							_active={{
								bg: "white",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
							color="white"
						>
							Add Apartment
						</Button>
					</FormControl>
				</form>
			</CardBody>
		</Card>
	);
};

export default AddApartments;
