import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
// Chakra imports
import { Flex, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";

import { BsFillPeopleFill } from "react-icons/bs";

import GlobalContext from "../../../Context.jsx";
import { UsersAPI } from "../../../Endpoints.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchAPI } from "../../../utils/fetchAPI.jsx";
import { numberWithCommas } from "../../../utils/index.js";
import MiniStatistics from "../Landlord/components/MiniStatistics.js";

const Index = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [totalUser, setTotalUsers] = useState(0);

	const [users, setUsers] = useState([]);

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res?.status === "success") {
				setTotalUsers(_res?.total_users);

				setUsers(_res?.users);
			} else {
				notify("Failed", "Could not get Users data", "error");
			}

			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get Users data", "error");
		};

		fetchAPI(UsersAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Fragment>
					<SimpleGrid
						columns={{ sm: 1, md: 2, xl: 4 }}
						spacing="24px"
					>
						<MiniStatistics
							title={"Total Users"}
							amount={numberWithCommas(totalUser)}
							icon={BsFillPeopleFill}
						/>
					</SimpleGrid>
					<Card p="16px" mt="20px" w="100%">
						<CardHeader>
							<Flex
								justify="space-between"
								align="center"
								minHeight="60px"
								w="100%"
							>
								<Text
									fontSize="lg"
									color={"#de9301"}
									fontWeight="bold"
								>
									Registered Users
								</Text>
							</Flex>
						</CardHeader>

						<CardBody
							display={"block"}
							style={{ flexDirection: "row" }}
						>
							<DataTable
								value={users}
								paginator
								rows={5}
								rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
							>
								{/* <Column
									sortable
									field="id"
									filter
									header="#"
									style={{ width: "25%" }}
								></Column> */}

								<Column
									sortable
									field="email"
									filter
									header="Email"
									body={(row) => row?.email}
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field="phone"
									filter
									header="Phone"
									body={(row) => row?.phone}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="created_at"
									filter
									header="Account created at"
									body={(row) => row?.created_at}
									style={{ width: "25%" }}
								></Column>
							</DataTable>
						</CardBody>
					</Card>
				</Fragment>
			)}
		</Flex>
	);
};

export default Index;
