// const getBaseUrl = () => {
// 	return window.location.hostname === "localhost"
// 		? "http://127.0.0.1:8000/api"
// 		: "https://africartz.xyz/api";
// };
// const baseUrl = getBaseUrl();

const baseUrl = "https://africartz.xyz/api";
const endpoint = (x) => `${baseUrl}${x}`;
// ------------------AFRICARTS-----------------------------

// Dashboard Endpoints
export const DashboardAPI = endpoint("/admin/dashboard");
export const GetAdminProfile = endpoint("/user");

// Bookings  Endpoints
export const BookingsAPI = endpoint("/admin/bookings");
export const ViewBookingsAPI = endpoint("/admin/bookings/details/");

// Apartment  Endpoints
export const ApartmentsAPI = endpoint("/admin/apartments");
export const AddApartmentsAPI = endpoint("/admin/apartments/add");
export const ViewApartmentsAPI = endpoint("/admin/apartments/details/");
export const updateApartmentsAPI = endpoint("/admin/apartments/details/");
export const deleteApartmentsAPI = endpoint("/admin/apartments/remove/");

export const DeleteApartmentPictureAPI = endpoint(
	"/admin/airbnb/apartment/picture/delete/"
); //{id}
export const DeleteApartmentVideoAPI = endpoint(
	"/admin/airbnb/apartment/video/delete/"
); //{id}

export const AddApartmentPictureAPI = endpoint(
	"/admin/airbnb/apartment/picture/"
); //{id}
export const AddApartmentVideoAPI = endpoint("/admin/airbnb/apartment/video/"); //{id}

export const SetDefaultApartmentPictureAPI = endpoint(
	"/admin/airbnb/apartment/picture/default/"
); //{apartmentId}/{pictureId}
export const SetDefaultApartmentvideoAPI = endpoint(
	"/admin/airbnb/apartment/video/default/"
); //{apartmentId}/{videoId}

//Landlords  Endpoints
export const LandlordsAPI = endpoint("/admin/landlords");
export const AddLandlordsAPI = endpoint("/admin/landlords/add");
export const ViewLandlordDetailsAPI = endpoint("/admin/landlords/details/");
export const UpdateLandlordDetailsAPI = endpoint("/admin/landlords/update/");

//Referrers Endpoints
export const ReferrersAPI = endpoint("/admin/referrers");
export const AddReferrersAPI = endpoint("/admin/referrers/add");
export const ViewReferrersAPI = endpoint("/admin/referrers/details/");

//Reservation-Request Endpoints
export const RequestsAPI = endpoint("/admin/reservation-requests");
export const ViewRequestsDetailsAPI = endpoint(
	"/admin/reservation-requests/details/"
);
export const AcceptRequests = endpoint("/admin/reservation-requests/accept");
export const DeclinedRequests = endpoint("/admin/reservation-requests/decline");

//Inspect Endpoints
export const InspectAPI = endpoint("/admin/inspection-requests");
export const ViewInspectionAPI = endpoint(
	"/admin/inspection-requests/details/"
);
export const ProcessInspectAPI = endpoint("/admin/inspection-requests/process");

//User Endpoints
export const UsersAPI = endpoint("/admin/users");

//Staff Endpoints
export const StaffAPI = endpoint("/admin/staff");
export const AddStaffAPI = endpoint("/admin/staff/add");
export const ToggleStaffAPI = endpoint("/admin/staff/toggle/");

// Manual Endpoints
export const ManualAPI = endpoint(
	"/admin/reservation-requests/approve/manual/"
);
export const GetManualRequestsAPI = endpoint(
	"/admin/reservation-requests/all/manual"
);
export const PostManualAPI = endpoint("/admin/reservation-requests/manual/");

// **************************************************************************************************************************************

export const AppLogo = endpoint("/logo");
export const plansAPI = endpoint("/plans");
export const paystackKeyAPI = endpoint("/paystack/key");

// AUTH Endpoints [Admin]
export const AdminLoginAPI = endpoint("/admin/login");
export const AdminRegisterAPI = endpoint("/admin/register");
export const AdminLogoutAPI = endpoint("/admin/logout");
export const AdminForgotPasswordAPI = endpoint("/admin/forgot-password");
export const AdminResetPasswordAPI = endpoint("/admin/reset-password");

// Admin Profile Endpoints
export const AdminGetProfileAPI = endpoint("/admin/profile");
export const AdminUpdateProfileAPI = endpoint("/admin/profile/update");

// Admin Settings Endpoints
export const AdminGetSettingsAPI = endpoint("/admin/app/settings");
export const AdminPaymentSettingsAPI = endpoint("/admin/app/payment");
export const AdminWebContentAPI = endpoint("/admin/app/webcontent");
export const AdminChangeLogoAPI = endpoint("/admin/app/logo");
