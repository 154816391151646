import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import GlobalContext from "./Context";

import AppLayout from "./Layouts/App";
import AuthLayout from "./Layouts/Auth";

// Admin Pages
import AdminForgotPassword from "./Pages/Admin/Auth/ForgotPassword";
import AdminLogin from "./Pages/Admin/Auth/Login";
import AdminProfile from "./Pages/Admin/Profile/Index";

import Dashboard from "./Pages/Admin/Dashboard/Index";
import Admin from "./Pages/Admin/Admin/Index";

import Reservation from "./Pages/Admin/Reservation/Index";
import ReservationDetails from "./Pages/Admin/Reservation/ReservationDetails";
import ManualBookings from "./Pages/Admin/Reservation/ManualBookings";

import Apartments from "./Pages/Admin/Apartments/Index";
import AddApartments from "./Pages/Admin/Apartments/New";
import ViewApartmentDetails from "./Pages/Admin/Apartments/ApartmentDetails";

import Bookings from "./Pages/Admin/Bookings/Index";
import ViewBookingDetails from "./Pages/Admin/Bookings/BookingDetails";

import Landlord from "./Pages/Admin/Landlord";
import AddLandlord from "./Pages/Admin/Landlord/new";
import ViewLandlordDetails from "./Pages/Admin/Landlord/LandlordDetails";

import Referrer from "./Pages/Admin/Referrer/Index";
import AddReferrer from "./Pages/Admin/Referrer/new";
import ViewReferrer from "./Pages/Admin/Referrer/ViewReferrer";

import Staffs from "./Pages/Admin/Staffs/Index";
import AddStaffs from "./Pages/Admin/Staffs/New";

import Inspection from "./Pages/Admin/Inspection/Index";
import InspectionRequestDetails from "./Pages/Admin/Inspection/InspectionRequestDetails";

import Users from "./Pages/Admin/Users/Index";

import AdminSettings from "./Pages/Admin/Settings/Index";
import ManualReservationDetails from "./Pages/Admin/Admin/ManualBooking";

const Authorize = () => {
	const { isLoggedIn, isAdmin } = useContext(GlobalContext);

	return (
		<Routes>
			{isLoggedIn ? (
				<>
					{/* Authenticated Routes */}
					<Route element={<AppLayout />}>
						{/* Admin Routes */}
						{isAdmin && (
							<>

								{/* Manual Reservation Requests */}
								<Route path={"/admin/manual"}>
									<Route index element={<Admin />} />

									<Route
										path={"reservation/:id"}
										element={<ManualReservationDetails />}
									/>
								</Route>

								{/* DASHBOARD */}
								<Route
									path={"/admin/Dashboard"}
									element={<Dashboard />}
								/>

								{/* BOOKING */}
								<Route path={"/admin/bookings"}>
									<Route index element={<Bookings />} />
									<Route
										path={":id"}
										element={<ViewBookingDetails />}
									/>
								</Route>

								{/* STAFFS */}
								<Route path="/admin/staffs">
									<Route index element={<Staffs />} />
									<Route path="New" element={<AddStaffs />} />
								</Route>

								{/* REFERRER */}
								<Route path="/admin/referrer">
									<Route index element={<Referrer />} />

									<Route
										path="New"
										element={<AddReferrer />}
									/>

									<Route
										path={":id"}
										element={<ViewReferrer />}
									/>
								</Route>

								{/* APARTMENT */}
								<Route path="/admin/apartments">
									<Route index element={<Apartments />} />
									<Route
										path="New"
										element={<AddApartments />}
									/>

									<Route
										path={":id"}
										element={<ViewApartmentDetails />}
									/>
								</Route>

								{/* LANDLORD */}
								<Route path="/admin/landlord">
									<Route index element={<Landlord />} />

									<Route
										path="new"
										element={<AddLandlord />}
									/>

									<Route
										path={":id"}
										element={<ViewLandlordDetails />}
									/>
								</Route>

								{/* INSPECTION */}
								<Route path="/admin/inspection">
									<Route index element={<Inspection />} />

									<Route
										path={":id"}
										element={<InspectionRequestDetails />}
									/>
								</Route>

								{/* USERS */}
								<Route
									path={"/admin/users"}
									element={<Users />}
								/>

								{/* REQUEST RESERVATION */}
								<Route path="/admin/reservation">
									<Route index element={<Reservation />} />

									<Route
										path={":id"}
										element={<ReservationDetails />}
									/>

									<Route
										path={
											"/admin/reservation/manualbookings"
										}
										element={<ManualBookings />}
									/>
								</Route>

								{/*  */}
								{/*  */}

								{/* SETTINGS */}
								<Route
									path={"/admin/settings"}
									element={<AdminSettings />}
								/>

								<Route
									path={"/admin/profile"}
									element={<AdminProfile />}
								/>
							</>
						)}

						{/* Student Routes */}
					</Route>
				</>
			) : (
				<>
					{/* Authorization Routes */}
					<Route element={<AuthLayout />}>
						{/* Admin Routes */}
						<Route path={"/login"} element={<AdminLogin />} />
						<Route
							path={"/forgot-password"}
							element={<AdminForgotPassword />}
						/>
					</Route>
				</>
			)}
		</Routes>
	);
};

export default Authorize;
