import React, { useState, useContext, useCallback, useEffect } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import { fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import { ViewReferrersAPI } from "../../../Endpoints.jsx";
import { useParams } from "react-router-dom";


const ReferrerDetails = () => {
	const { id } = useParams();

	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	// const [processloading, setProcessLoading] = useState(false);

	const [referrer, SetReferrer] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		ref_code: "",
		other_phone: "",
		account_name: "",
		account_number: "",
		bank_name: "",
		created_at: "",
	});

	const notify = useNotifier();

	const fetchLandlordDetails = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Success", _res?.message, "success");
			const { referrer } = _res;
			SetReferrer({
				first_name: referrer.first_name,
				last_name: referrer.last_name,
				email: referrer.email,
				phone: referrer.phone,
				ref_code: referrer.ref_code,
				other_phone: referrer.other_phone,
				account_name: referrer.account_name,
				account_number: referrer.account_number,
				bank_name: referrer.bank_name,
				created_at: referrer.created_at,
			});
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: ViewReferrersAPI + id,
			method: "GET",
			token,
			handleSuccess,
			handleError,
		});
		setLoading(false);
	}, [id, notify, token]);

	useEffect(() => {
		fetchLandlordDetails();
		// eslint-disable-next-line
	}, []);

	return (
		<Flex flexDirection={"row"} pt={{ base: "120px", md: "75px" }}>
			<Card p="16px" w={{ md: "100%", sm: "100%" }}>
				<CardBody display={"block"}>
					{loading ? (
						<Spinner />
					) : (
						<React.Fragment>
							<Stack paddingBottom={"4"}>
								<Text fontSize="lg" fontWeight={"bold"}>
									{" "}
									Referrer Details
								</Text>

								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Customer Name:{referrer.first_name} &nbsp;{" "}
									{referrer.last_name}
								</Text>

								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Email: {referrer.email}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Phone: {referrer.phone}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Ref Code: {referrer.ref_code}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Other Phone: {referrer.other_phone}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Account Name: {referrer.account_name}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									account Number:
									{referrer.account_number}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Bank Name: {referrer.bank_name}
								</Text>
								<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									Date Created: {referrer.created_at}
								</Text>
							</Stack>
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ReferrerDetails;
