// import React, { useState, useContext, useCallback, useEffect } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import { fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import { AddApartmentsAPI, ManualAPI } from "../../../Endpoints.jsx";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

const inputStyles = { opacity: 1, cursor: "text" };

const ManualBookings = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [manualBookings, setManualBookings] = useState({
		name: "",
		address: "",
		amenities: "",
		num_of_guests: "",
		num_of_bedrooms: "",
		num_of_bathrooms: "",
		num_of_beds: "",
		owner_id: "",
		africartz_fee: "",
		landlord_fee: "",
		caution_fee: "",
		available_dates: "",
		location: "",
	});

	const handleChange = (prop) => (e) => {
		const { value } = e.target;
		setManualBookings((prev) => ({ ...prev, [prop]: value }));
	};

	const notify = useNotifier();

	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Success", _res?.message, "success");

			setManualBookings({
				name: "",
				address: "",
				amenities: "",
				num_of_guests: "",
				num_of_bedrooms: "",
				num_of_bathrooms: "",
				num_of_beds: "",
				owner_id: "",
				africartz_fee: "",
				landlord_fee: "",
				caution_fee: "",
				available_dates: "",
				location: "",
			});
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: AddApartmentsAPI,
			method: "POST",
			// data: manualBookings,
			token,
			handleSuccess,
			handleError,
		});
	};

	return (
		<Flex flexDirection={"row"} pt={{ base: "120px", md: "75px" }}>
			<Card p="16px" w={{ md: "100%", sm: "100%" }}>
				<CardBody display={"block"}>
					<Text
						fontSize={"3xl"}
						fontWeight={"semibold"}
						mb={"6"}
						textColor={"#de9301"}
					>
						Manual Booking{" "}
					</Text>
					<Stack paddingBottom={"4"}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							{" "}
							Apartment
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Name:
						</Text>
					</Stack>
					{/*  */}

					<Stack paddingBottom={"4"}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							Reservation Dates
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Check In:
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Check Out:
						</Text>
					</Stack>
					{/*  */}
					<Stack paddingBottom={"4"}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							Landlord
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Landlord Name:
						</Text>
					</Stack>
					{/*  */}
					<Stack paddingBottom={"4"}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							Customer
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Customer Name:
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Customer Phone No:
						</Text>
					</Stack>
					<Stack paddingBottom={"4"}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							Summary
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Price:
						</Text>
						<Text fontSize="xl" fontWeight={"light"}>
							Caution fee:
						</Text>

						<Text fontSize="xl" fontWeight={"bold"}>
							Kindly upload a payment recepit of{" "}
							<span>#000,000 </span> to confirm booking
						</Text>
					</Stack>

					<Flex gap={"4"}>
						<Button
							type="submit"
							isLoading={loading}
							fontSize="12px"
							fontWeight="medium"
							borderRadius={"100px"}
							px={6}
							w="max"
							mt={4}
							bg="gray.500"
							_hover="none"
							_active={{
								bg: "white",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
							color="white"
						>
							Upload payment receipt
						</Button>

						<Button
							type="submit"
							isLoading={loading}
							fontSize="12px"
							fontWeight="medium"
							borderRadius={"100px"}
							px={6}
							w="max"
							mt={4}
							bg="green"
							_hover="none"
							_active={{
								bg: "white",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
							color="white"
						>
							Send
						</Button>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ManualBookings;
