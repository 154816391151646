import React, { useState, useContext, useEffect, useCallback } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import { fetchAPI, fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import {
	ViewBookingsAPI,
	SetDefaultApartmentPictureAPI,
} from "../../../Endpoints.jsx";
import { useParams } from "react-router-dom";


const BookingDetails = () => {
	const { id } = useParams();

	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [booking, setBookings] = useState({
		apartment: {
			name: "",
			address: "",
			defaultPicture: null,
			pictures: [],
		},
		start_date: "",
		end_date: "",
		amount: "",
		status: "",
		email: "",
		means_of_id: "",
		first_name: "",
		last_name: "",
		phone: "",
		id_number: "",
		id_image: "",
	});

	const notify = useNotifier();
	const [pictures, setpictures] = useState([]);
	const [defaultpicture, setdefaultpicture] = useState();

	const fetchLandlordDetails = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Success", _res?.message, "success");
			const { booking } = _res;
			setBookings({
				apartment: {
					name: booking.apartment.name,
					address: booking.apartment.address,
					defaultPicture: booking.apartment.defaultPicture,
					pictures: booking.apartment.pictures,
				},
				start_date: booking.start_date,
				end_date: booking.end_date,
				amount: booking.amount,
				status: booking.status,
				email: booking.email,
				means_of_id: booking.means_of_id,
				first_name: booking.first_name,
				last_name: booking.last_name,
				phone: booking.phone,
				id_number: booking.id_number,
				id_image: booking.id_image,
			});
			setdefaultpicture(booking?.apartment?.defaultPicture);
			setpictures(booking?.apartment?.pictures);
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: ViewBookingsAPI + id,
			method: "GET",
			token,
			handleSuccess,
			handleError,
		});
	}, [id, notify, token]);

	useEffect(() => {
		fetchLandlordDetails();
		// eslint-disable-next-line
	}, []);

	return (
		<Card p="16px" pt={{ base: "120px", md: "75px" }} >
			{loading && <Spinner colorScheme="orange" size="lg" /> }
			{!loading && (<>
				<CardBody display={"block"}>
					<Stack paddingBottom={"4"}>
						<Text fontSize="2xl"  fontWeight={"bold"} >
							{" "}
							Bookings Details
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Apartment Name:{booking.apartment.name}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Apartment Address:{booking.apartment.address}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Start Date:{booking.start_date}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							End Date:{booking.end_date}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Amount:{booking.amount}
						</Text>
					</Stack>
					{/*  */}

					<Stack paddingBottom={"4"}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							Reservation Dates
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							status:{booking.status}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Email:{booking.email}
						</Text>
					</Stack>
					{/*  */}
					<Stack paddingBottom={""}>
						<Text fontSize="lg" fontWeight={"semibold"}>
							Customers Info
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Means of Id:{booking.means_of_id}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							First Name:{booking.first_name}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Last name:{booking.last_name}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							Phone:{booking.phone}
						</Text>
						<Text fontSize="xl" fontWeight={"light"} color={"gray.500"}>
							ID Number:{booking.id_number}
						</Text>

						{/*  */}
					</Stack>
				</CardBody>
				<Card p="16px" w="100%" my={{ base: "120px", md: "75px" }}>
					<CardBody flexDirection={"column"}>
						<Text size={"lg"} fontWeight={"semibold"}>
							Pictures
						</Text>
						<aside
							style={{
								display: "flex",
								flexFlow: "wrap",
								marginTop: "16px",
							}}
						>
							{pictures?.map((x, i) => (
								<ApartmentPicture
									key={i}
									data={x}
									default={x?.id === defaultpicture?.id}
								/>
							))}
						</aside>
					</CardBody>
				</Card>
			</>)}
		</Card>
	);
};

export default BookingDetails;

const ApartmentPicture = (props) => {
	const {
		data: { id, apartment_id, fullPath },
	} = props;

	const [loading, setLoading] = useState(false);
	const { token } = useContext(GlobalContext);

	const notify = useNotifier();

	const setAsDefault = () => {
		setLoading(true);
		const handleSuccess = (_res) => {
			notify("", "Done", "success");
			setLoading(!true);
		};

		const handleError = (_err) => {
			setLoading(!true);
			notify("Failed", "Could not set picture as default", "error");
		};

		fetchAPI(
			SetDefaultApartmentPictureAPI + apartment_id + "/" + id,
			handleSuccess,
			handleError,
			token
		);
	};

	return (
		<div
			style={{
				display: "inline-flex",
				borderRadius: "2px",
				border: "1px solid rgb(234, 234, 234)",
				marginBottom: "8px",
				marginRight: "8px",
				width: "fit-content",
				maxWidth: "100px",
				height: "100px",
				padding: "4px",
				boxSizing: "border-box",
			}}
		>
			{!props?.default && (
				<button
					style={{ position: "absolute" }}
					type="button"
					onClick={setAsDefault}
					title="Set as default picture"
					className=""
				></button>
			)}

			{props?.default && (
				<span
					style={{
						background: "aliceblue",
						padding: ".1rem",
						borderRadius: ".5rem",
						position: "absolute",
						fontSize: ".6rem",
					}}
				>
					DEFAULT
				</span>
			)}

			{loading && (
				<Spinner
					color="orange"
					style={{
						position: "absolute",
						marginLeft: 35,
						marginTop: 30,
					}}
				/>
			)}

			<div style={{ display: "flex", minWidth: "0px", overflow: "hidden" }}>
				<img src={fullPath} style={{ display: "block", width: "100%", height: "100%" }} alt="" />
			</div>
		</div>
	);
};
