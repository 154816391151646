import React from "react";
import Select from "react-select";

const SelectDropdown = ({
	selected,
	handleSelect,
	options,
	isMulti,
	disabled,
}) => {
	const handleChange = (selectedOption) => {
		handleSelect(selectedOption);
	};

	return (
		<Select
			options={options}
			value={selected}
			onChange={handleChange}
			isMulti={isMulti}
			isDisabled={disabled}
			styles={{
				control: (baseStyles) => ({
					...baseStyles,
					height: "auto",
					maxHeight: "100px",
					overflow: "auto",
				}),
			}}
		/>
	);
};

export default SelectDropdown;
