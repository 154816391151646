import {
	ButtonGroup,
	Flex,
	IconButton,
	SimpleGrid,
	Spinner,
	Text,
	Tooltip,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { IoReloadSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context.jsx";
import { BookingsAPI } from "../../../Endpoints.jsx";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchAPI } from "../../../utils/fetchAPI.jsx";
import { numberWithCommas } from "../../../utils/index.js";
import MiniStatistics from "../Landlord/components/MiniStatistics.js";

const Index = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [totalBooking, setTotalBooking] = useState(0);
	const [checkOut, setCheckout] = useState(0);
	const [bookings, setBookings] = useState([]);

	const notify = useNotifier();
	const navigate = useNavigate();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res?.status === "success") {
				setTotalBooking(Number(_res?.bookings_count));
				setCheckout(Number(_res?.check_outs));

				setBookings(_res?.bookings);
			} else {
				notify("Failed", "Could not get Bookings data", "error");
			}

			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get Bookings data", "error");
		};

		fetchAPI(BookingsAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex direction="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Fragment>
					<SimpleGrid columns={{ sm: 1, md: 3 }} spacing="24px">
						<MiniStatistics
							title={"No. of Bookings"}
							amount={numberWithCommas(totalBooking)}
							icon={BsFillPeopleFill}
						/>

						<MiniStatistics
							title={"No. of Check-out"}
							amount={numberWithCommas(checkOut)}
							icon={BsFillPeopleFill}
						/>
					</SimpleGrid>

					<Card p="16px" mt="20px" w="100%">
						<CardHeader>
							<Flex
								justify="space-between"
								align="center"
								minHeight="60px"
								w="100%"
							>
								<Text
									fontSize="lg"
									color={"#de9301"}
									fontWeight="bold"
								>
									Bookings
								</Text>

								<Flex justify="flex-end">
									<Tooltip label="Reload">
										<IconButton
											icon={<IoReloadSharp />}
											onClick={() => fetchData()}
										/>
									</Tooltip>
								</Flex>
							</Flex>
						</CardHeader>

						<CardBody
							display={"block"}
							style={{ flexDirection: "row" }}
						>
							<DataTable
								value={bookings}
								paginator
								rows={5}
								rowsPerPageOptions={[5, 10, 25, 50]}
							>
								{/* <Column
									sortable
									field="id"
									filter
									header="#"
									style={{ width: "25%" }}
								></Column> */}
								<Column
									sortable
									field="apartment"
									filter
									header="Apartment"
									body={(row) => row?.apartment?.name ?? "N/A"}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="trx_ref"
									filter
									header="Transaction Ref"
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="landlord"
									filter
									header="Landlord"
									body={(row) =>
										row?.landlord.first_name +
										" " +
										row?.landlord.last_name
									}
									style={{
										width: "25%",
										textTransform: "capitalize",
									}}
								></Column>
								<Column
									sortable
									field="customer"
									filter
									header="Customer"
									body={(row) =>
										row?.first_name + " " + row?.last_name
									}
									style={{
										width: "25%",
										textTransform: "capitalize",
									}}
								></Column>
								<Column
									sortable
									field="created_at"
									filter
									header="Date/Time"
									body={(row) => (
										<Text>
											{dayjs(row?.created_at).format(
												"ddd D MMM YYYY"
											)}
										</Text>
									)}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="status"
									filter
									header="Status"
									body={(row) => <>{row?.status}</>}
									style={{
										width: "25%",
										textTransform: "capitalize",
									}}
								></Column>
								<Column
									sortable
									field="referral_code"
									filter
									header="Referral Code"
									body={(row) => row?.referral_code ?? "N/A"}
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field=""
									header="Action"
									body={(row) => (
										<ButtonGroup
											size="sm"
											isAttached
											variant="ghost"
										>
											<Tooltip label="View">
												<IconButton
													colorScheme="messenger"
													onClick={() =>
														navigate(
															`/admin/bookings/${row?.id}`
														)
													}
													aria-label="View"
													icon={<FaEye />}
												/>
											</Tooltip>
										</ButtonGroup>
									)}
									style={{ width: "25%" }}
								></Column>
							</DataTable>
						</CardBody>
					</Card>
				</Fragment>
			)}
		</Flex>
	);
};

export default Index;
