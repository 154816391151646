/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Icon,
	Link,
	Stack,
	Text,
	useColorModeValue,
	useDisclosure,
} from "@chakra-ui/react";

import React, { Fragment, useContext } from "react";
import {
	FaBookOpen,
	FaBuildingUser,
	FaUser,
	FaUserTag,
	FaUsers,
} from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { LuInspect } from "react-icons/lu";
import { MdAdminPanelSettings, MdApartment, MdEditNote } from "react-icons/md";
import { RiReservedFill } from "react-icons/ri";
import { SlBookOpen } from "react-icons/sl";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";
import IconBox from "../../components/Icons/IconBox";
import { CreativeTimLogo } from "../../components/Icons/Icons";
import { Separator } from "../../components/Separator/Separator";
import SideBarLink from "./SideBarLink";
import { SidebarHelp } from "./SidebarHelp";

// import { AppLogo } from "../../Endpoints";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import GlobalContext from "../../Context";
import AfricartzLogo from "../../assets/logo.png";

function SidebarResponsive(props) {
	const { isAdmin, isStudent, profile } = useContext(GlobalContext);

	// to check for active links and opened collapses
	let location = useLocation();
	// this is for the rest of the collapses
	const [state, setState] = React.useState({});
	const mainPanel = React.useRef();
	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return location.pathname === routeName ? "active" : "";
	};

	const createLinks = (routes = []) => {
		// Chakra Color Mode
		const activeBg = useColorModeValue("white", "gray.700");
		const inactiveBg = useColorModeValue("white", "gray.700");
		const activeColor = useColorModeValue("gray.700", "white");
		const inactiveColor = useColorModeValue("gray.400", "gray.400");

		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.category) {
				var st = {};
				st[prop["state"]] = !state[prop.state];
				return (
					<div key={prop.name}>
						<Text
							color={activeColor}
							fontWeight="bold"
							mb={{
								xl: "12px",
							}}
							mx="auto"
							ps={{
								sm: "10px",
								xl: "16px",
							}}
							py="12px"
						>
							{document.documentElement.dir === "rtl"
								? prop.rtlName
								: prop.name}
						</Text>
						{createLinks(prop.views)}
					</div>
				);
			}
			return (
				<NavLink to={prop.layout + prop.path} key={prop.name}>
					{activeRoute(prop.layout + prop.path) === "active" ? (
						<Button
							boxSize="initial"
							justifyContent="flex-start"
							alignItems="center"
							bg={activeBg}
							mb={{
								xl: "12px",
							}}
							mx={{
								xl: "auto",
							}}
							ps={{
								sm: "10px",
								xl: "16px",
							}}
							py="12px"
							borderRadius="15px"
							_hover="none"
							w="100%"
							_active={{
								bg: "inherit",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
						>
							<Flex>
								{typeof prop.icon === "string" ? (
									<Icon>{prop.icon}</Icon>
								) : (
									<IconBox
										bg="red.300"
										color="white"
										h="30px"
										w="30px"
										me="12px"
									>
										{prop.icon}
									</IconBox>
								)}
								<Text
									color={activeColor}
									my="auto"
									fontSize="sm"
								>
									{document.documentElement.dir === "rtl"
										? prop.rtlName
										: prop.name}
								</Text>
							</Flex>
						</Button>
					) : (
						<Button
							boxSize="initial"
							justifyContent="flex-start"
							alignItems="center"
							bg="transparent"
							mb={{
								xl: "12px",
							}}
							mx={{
								xl: "auto",
							}}
							py="12px"
							ps={{
								sm: "10px",
								xl: "16px",
							}}
							borderRadius="15px"
							_hover="none"
							w="100%"
							_active={{
								bg: "inherit",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
						>
							<Flex>
								{typeof prop.icon === "string" ? (
									<Icon>{prop.icon}</Icon>
								) : (
									<IconBox
										bg={inactiveBg}
										color="red.300"
										h="30px"
										w="30px"
										me="12px"
									>
										{prop.icon}
									</IconBox>
								)}
								<Text
									color={inactiveColor}
									my="auto"
									fontSize="sm"
								>
									{document.documentElement.dir === "rtl"
										? prop.rtlName
										: prop.name}
								</Text>
							</Flex>
						</Button>
					)}
				</NavLink>
			);
		});
	};

	const { logoText, routes, ...rest } = props;

	var links = <>{createLinks(routes)}</>;
	//  BRAND
	//  Chakra Color Mode
	let hamburgerColor = useColorModeValue("gray.500", "gray.200");
	if (props.secondary === true) {
		hamburgerColor = "white";
	}
	var brand = (
		<Box pt={"35px"} mb="8px">
			<Link
				href={`${process.env.PUBLIC_URL}/#/`}
				target="_blank"
				display="flex"
				lineHeight="100%"
				mb="30px"
				fontWeight="bold"
				justifyContent="center"
				alignItems="center"
				fontSize="11px"
			>
				<CreativeTimLogo w="32px" h="32px" me="10px" />
				<Text fontSize="sm" mt="3px">
					{logoText}
				</Text>
			</Link>
			<Separator />
		</Box>
	);

	// SIDEBAR
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();
	// Color variables
	return (
		<Flex
			display={{ sm: "flex", xl: "none" }}
			ref={mainPanel}
			alignItems="center"
		>
			<HamburgerIcon
				color={hamburgerColor}
				w="18px"
				h="18px"
				ref={btnRef}
				colorScheme="red"
				onClick={onOpen}
			/>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={
					document.documentElement.dir === "rtl" ? "right" : "left"
				}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent
					w="250px"
					maxW="250px"
					ms={{
						sm: "16px",
					}}
					my={{
						sm: "16px",
					}}
					borderRadius="16px"
				>
					<DrawerCloseButton
						_focus={{ boxShadow: "none" }}
						_hover={{ boxShadow: "none" }}
					/>
					<DrawerBody maxW="250px" px="1rem">
						<Box maxW="100%" h="100vh">
							{/* <Box>{brand}</Box> */}
							{/* <Stack direction="column" mb="40px">
                  <Box>{links}</Box>
                </Stack> */}

							<Box pt={"25px"} mb="12px">
								<Link
									href="/"
									target="_blank"
									display="flex"
									lineHeight="100%"
									mb="30px"
									fontWeight="bold"
									justifyContent="center"
									alignItems="center"
									fontSize="11px"
								>
									<img
										src={AfricartzLogo}
										w="32px"
										h="32px"
										me="10px"
										alt={logoText}
									/>
								</Link>
								<Separator />
							</Box>
							<Stack direction="column" mb="40px">
								{isAdmin && (
									<Fragment>
										<SideBarLink
											text="Dashboard"
											icon={
												<Icon
													as={TbLayoutDashboardFilled}
													w={5}
													h={5}
												/>
											}
											route="admin/dashboard"
										/>
										<SideBarLink
											text="Bookings"
											icon={
												<Icon
													as={FaBookOpen}
													w={5}
													h={5}
												/>
											}
											route="admin/bookings"
										/>
										<SideBarLink
											text="Apartments"
											icon={
												<Icon
													as={MdApartment}
													w={5}
													h={5}
												/>
											}
											route="admin/apartments"
										/>
										<SideBarLink
											text="Landlords"
											icon={
												<Icon
													as={FaBuildingUser}
													w={5}
													h={5}
												/>
											}
											route="admin/landlord"
										/>
										<SideBarLink
											text="Referrals"
											icon={
												<Icon
													as={FaUserTag}
													w={5}
													h={5}
												/>
											}
											route="admin/referrer"
										/>
										<SideBarLink
											text="Reservation Request"
											icon={
												<Icon
													as={RiReservedFill}
													w={5}
													h={5}
												/>
											}
											route="admin/reservation"
										/>
										<SideBarLink
											text="Inspection Request"
											icon={
												<Icon
													as={LuInspect}
													w={5}
													h={5}
												/>
											}
											route="admin/inspection"
										/>
										<SideBarLink
											text="Users"
											icon={
												<Icon
													as={FaUsers}
													w={5}
													h={5}
												/>
											}
											route="admin/users"
										/>

										{/*
										 */}

										{profile?.role === "admin" && (
											<>
												<SideBarLink
													text="Staffs"
													icon={
														<Icon
															as={FaUser}
															w={5}
															h={5}
														/>
													}
													route="admin/staffs"
												/>
												<SideBarLink
													text="Admin"
													icon={
														<Icon
															as={
																MdAdminPanelSettings
															}
															w={5}
															h={5}
														/>
													}
													route="admin/admin"
												/>
											</>
										)}
									</Fragment>
								)}

								{isStudent && (
									<Fragment>
										<SideBarLink
											text="Dashboard"
											icon={
												<Icon
													as={TbLayoutDashboardFilled}
													w={5}
													h={5}
													color="blue.400"
												/>
											}
											route="user/dashboard"
										/>
										<SideBarLink
											text="Courses"
											icon={
												<Icon
													as={SlBookOpen}
													w={5}
													h={5}
													color="blue.400"
												/>
											}
											route="user/courses"
										/>
										<SideBarLink
											text="Tests"
											icon={
												<Icon
													as={
														HiOutlineComputerDesktop
													}
													w={5}
													h={5}
													color="blue.400"
												/>
											}
											route="user/tests"
										/>
										<SideBarLink
											text="Book Test"
											icon={
												<Icon
													as={MdEditNote}
													w={5}
													h={5}
													color="blue.400"
												/>
											}
											route="user/exam"
										/>
										<SideBarLink
											text="Billing"
											icon={
												<Icon
													as={GiMoneyStack}
													w={5}
													h={5}
													color="blue.400"
												/>
											}
											route="user/billing"
										/>
									</Fragment>
								)}
							</Stack>
							<SidebarHelp />
						</Box>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
}

export default SidebarResponsive;
