import React, { useState, useContext, useCallback, useEffect } from "react";
import {
	Button,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Input,
	Spinner,
	Switch,
	Text,
	Textarea,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import GlobalContext from "../../../Context.jsx";
import {
	ViewApartmentsAPI,
	updateApartmentsAPI,
	LandlordsAPI,
	SetDefaultApartmentPictureAPI,
	DeleteApartmentPictureAPI,
	DeleteApartmentVideoAPI,
} from "../../../Endpoints.jsx";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import useNotifier from "../../../hooks/useNotifier.jsx";
import {
	fetchAPI,
	fetchProtectedResource,
	postAuth,
} from "../../../utils/fetchAPI.jsx";

import SelectDropdown from "./SelectDropdown.jsx";
import ToggleInput from "../../../components/ToggleInput.jsx";
import AmountInput from "../../../components/AmountInput.jsx";
import IntegerInput from "../../../components/IntegerInput.jsx";
import PushApartmentPictures from "../../../components/PushApartmentPictures.jsx";
import PushApartmentVideos from "../../../components/PushApartmentVideos.jsx";
import ToggleSymbols from "../../../components/ToggleSymbols.jsx";

const inputStyles = { opacity: 1, cursor: "text" };

export const getAfricartzFee = (price) => {
	if (price < 100_000) return 6_000;
	else if (price < 200_000) return 12_000;
	else if (price < 300_000) return 20_000;
	else if (price < 400_000) return 28_000;
	else if (price < 500_000) return 35_000;
	else if (price < 800_000) return 40_000;
	else return 60_000;
};

const ApartmentDetails = () => {
	const { id } = useParams();
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [name, setname] = useState();
	const [landlord, setlandlord] = useState();
	const [state, setstate] = useState();
	const [address, setaddress] = useState();
	const [location, setlocation] = useState();
	const [num_of_guests, setnum_of_guests] = useState("");
	const [num_of_bedrooms, setnum_of_bedrooms] = useState("");
	const [num_of_bathrooms, setnum_of_bathrooms] = useState("");
	const [num_of_beds, setnum_of_beds] = useState("");

	const [africartz_fee, setafricartz_fee] = useState("");
	const [landlord_fee, setlandlord_fee] = useState("");
	const [caution_fee, setcaution_fee] = useState("");
	const [status, setstatus] = useState("");

	const [photo_shoot_fee, setphoto_shoot_fee] = useState();
	const [photo_shoot_fee_visible, setphoto_shoot_fee_visible] =
		useState(false);
	const [movie_shoot_fee, setmovie_shoot_fee] = useState();
	const [movie_shoot_fee_visible, setmovie_shoot_fee_visible] =
		useState(false);
	const [birthday_fee, setbirthday_fee] = useState();
	const [birthday_fee_visible, setbirthday_fee_visible] = useState(false);
	// const [available_dates, setavailable_dates] = useState();
	const [amenities, setamenities] = useState();
	const [description, setdescription] = useState();

	const [pictures, setpictures] = useState([]);
	const [videos, setvideos] = useState([]);
	const [defaultpicture, setdefaultpicture] = useState();
	const [defaultVideo, setdefaultVideo] = useState();

	const notify = useNotifier();

	const stateOptions = [
		{ value: "Lagos", label: "Lagos" },
		{ value: "Abuja", label: "Abuja" },
	];

	const [landlordOptions, setLandlordOptions] = useState([]);

	const amenitiesOptions = [
		{ value: "24/7 power supply", label: "24/7 power supply" },
		{ value: "Fitted Kitchen", label: "Fitted Kitchen" },
		{ value: "House keeping service", label: "House keeping service" },
		{ value: "Gated & secured estate", label: "Gated & secured estate" },
		{ value: "24/7 security guards", label: "24/7 security guards" },
		{ value: "Parking slot", label: "Parking slot" },
		{ value: "DSTV", label: "DSTV" },
		{ value: "Internet", label: "Internet" },
		{ value: "Netflix", label: "Netflix" },
		{
			value: "Children recreational area",
			label: "Children recreational area",
		},
		{ value: "Serene environment", label: "Serene environment" },
		{ value: "Ps4", label: "Ps4" },
		{ value: "Ps5", label: "Ps5" },
		{ value: "Snooker board", label: "Snooker board" },
		{ value: "Gym", label: "Gym" },
		{ value: "Pool", label: "Pool" },
		{ value: "En-suite rooms", label: "En-suite rooms" },
	];

	/*
	const validateForm = () => {
		let requiredFields = [
			name,
			landlord,
			state,
			address,
			location,
			num_of_guests,
			num_of_bedrooms,
			num_of_bathrooms,
			num_of_beds,
			africartz_fee,
			landlord_fee,
			caution_fee,
		];

		for (let field of requiredFields) {
			if (isInValid(field)) {
				notify("", "All input fields are required", "error");
				return false;
			}
		}

		return true;
	};
	*/

	const getApartmentDetails = useCallback(
		(landlordList = landlordOptions) => {
			setLoading(true);

			const handleSuccess = (_res) => {
				notify("Success", _res?.message, "success");
				const { apartment, videos } = _res;

				let split = apartment?.amenities.split(",");
				let amm = [];

				split.forEach((x) => {
					let am = amenitiesOptions.filter((y) => x === y?.value)[0];
					amm.push(am);
				});

				setname(apartment?.name);
				setlandlord(
					landlordList.filter(
						(y) => Number(apartment?.owner_id) === Number(y?.value)
					)[0]
				);
				setstate({ value: apartment?.state, label: apartment?.state });
				setaddress(apartment?.address);
				setlocation(apartment?.location);
				setnum_of_guests(apartment?.num_of_guests);
				setnum_of_bedrooms(apartment?.num_of_bedrooms);
				setnum_of_bathrooms(apartment?.num_of_bathrooms);
				setnum_of_beds(apartment?.num_of_beds);
				setafricartz_fee(apartment?.africartz_fee);
				setlandlord_fee(apartment?.landlord_fee);
				setcaution_fee(apartment?.caution_fee);
				setphoto_shoot_fee(apartment?.photo_shoot_fee);
				setphoto_shoot_fee_visible(
					apartment?.photo_shoot_fee ? true : false
				);
				setmovie_shoot_fee(apartment?.movie_shoot_fee);
				setmovie_shoot_fee_visible(
					apartment?.movie_shoot_fee ? true : false
				);
				setbirthday_fee(apartment?.birthday_fee);
				setbirthday_fee_visible(apartment?.birthday_fee ? true : false);
				// setavailable_dates(apartment?.available_dates);
				setamenities(amm);
				setdescription(apartment?.description);
				setstatus(apartment?.status);

				setpictures(apartment?.pictures);
				setvideos(videos);

				setdefaultpicture(apartment?.defaultPicture);
				// setdefaultVideo(apartment?.defaultVideo);
				setLoading(false);
			};

			const handleError = () => {
				setLoading(false);
				notify(
					"Failed",
					"Kindly check your internet connection andn try again.",
					"error"
				);
			};

			fetchProtectedResource({
				url: ViewApartmentsAPI + id,
				method: "GET",
				token,
				handleSuccess,
				handleError,
			});

			// eslint-disable-next-line
		},
		[id, landlordOptions, notify, token]
	);

	const fetchLandlordList = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res.status === "success") {
				const landlordList = _res.landlords.map((item) => ({
					label:
						item.first_name.charAt(0).toUpperCase() +
						item.first_name.slice(1) +
						" " +
						item.last_name.charAt(0).toUpperCase() +
						item.last_name.slice(1),
					value: String(item.id),
				}));

				setLandlordOptions(() => landlordList);
				getApartmentDetails(landlordList);
			} else {
				notify("", "Failed to get landlords", "error");
			}

			setLoading(false);
		};

		const handleError = (err) => {
			setLoading(false);
			notify("", err.message, "error");
		};

		fetchProtectedResource({
			url: LandlordsAPI,
			token,
			handleSuccess,
			handleError,
		});
	}, [getApartmentDetails, notify, token]);

	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		const handleSuccess = (_res) => {
			setLoading(false);

			if (_res?.status === "success") {
				notify("", "Your changes have been saved.", "success");
			} else {
				let errors = _res?.errors;

				if (errors && Object.keys(errors).length > 0) {
					console.log(Object.keys(errors));
					for (let err in errors) {
						notify("", errors[`${err}`][0], "error");
					}
				} else {
					notify("Failed", _res?.message, "error");
				}
			}
		};

		const handleError = (e) => {
			setLoading(false);

			let errors = e?.errors;

			if (errors && Object.keys(errors).length > 0) {
				console.log(Object.keys(errors));
				for (let err in errors) {
					notify("", errors[`${err}`][0], "error");
				}
			} else {
				notify("Failed", e?.message, "error");
			}
		};

		let am = [];

		amenities.forEach((x) => {
			am.push(x?.value);
		});

		const requestBody = {
			name,
			address,
			amenities: am.join(","),
			num_of_guests,
			num_of_bedrooms,
			num_of_bathrooms,
			num_of_beds,
			owner_id: landlord?.value,
			africartz_fee,
			landlord_fee,
			caution_fee,
			// available_dates,
			location,
			state: state.value,
			photo_shoot_fee: photo_shoot_fee_visible ? photo_shoot_fee : null,
			movie_shoot_fee: movie_shoot_fee_visible ? movie_shoot_fee : null,
			birthday_fee: birthday_fee_visible ? birthday_fee : null,
			description,
			is_booked: status,
		};

		postAuth(
			updateApartmentsAPI + id,
			requestBody,
			handleSuccess,
			handleError,
			token
		);
	};

	useEffect(() => {
		fetchLandlordList();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let af_fee = getAfricartzFee(landlord_fee);
		setafricartz_fee(af_fee);
	}, [landlord_fee]);

	return (
		<React.Fragment>
			<Card
				p="16px"
				w="100%"
				pt={{ base: "120px", md: "75px" }}
				my={{ base: "120px", md: "75px" }}
			>
				<CardBody>
					<form onSubmit={onSubmit}>
						<Grid
							templateColumns={{
								base: "repeat(1, 1fr)",
								md: "repeat(2, 1fr)",
								lg: "repeat(4, 1fr)",
							}}
							gap={6}
						>
							<GridItem>
								<label>Name</label>
								<Input
									size="md"
									value={name}
									style={inputStyles}
									onChange={(x) => setname(x.target.value)}
								/>
							</GridItem>

							<GridItem>
								<label>Select Landlord</label>
								<SelectDropdown
									selected={landlord}
									value={landlord}
									handleSelect={(value) => setlandlord(value)}
									options={landlordOptions}
									isMulti={false}
									disabled={loading}
								/>
							</GridItem>
							{/* <GridItem>
								<label>Available Dates</label>
								<Input
									type="date"
									size="md"
									value={available_dates}
									style={inputStyles}
									onChange={(x) =>
										setavailable_dates(x?.target?.value)
									}
								/>
							</GridItem> */}

							<GridItem>
								<label>Amenities</label>
								<SelectDropdown
									selected={amenities}
									value={amenities}
									handleSelect={(value) =>
										setamenities(value)
									}
									options={amenitiesOptions}
									isMulti={true}
								/>
							</GridItem>

							<GridItem>
								<label>No. of Guest</label>
								<IntegerInput
									value={num_of_guests}
									setValue={setnum_of_guests}
								/>
							</GridItem>
							<GridItem>
								<label>No. of Bedrooms</label>
								<IntegerInput
									value={num_of_bedrooms}
									setValue={setnum_of_bedrooms}
								/>
							</GridItem>
							<GridItem>
								<label>No. of Bathrooms</label>
								<IntegerInput
									value={num_of_bathrooms}
									setValue={setnum_of_bathrooms}
								/>
							</GridItem>
							<GridItem>
								<label>No. of Beds</label>
								<IntegerInput
									value={num_of_beds}
									setValue={setnum_of_beds}
								/>
							</GridItem>

							<GridItem>
								<label>Default stay fee</label>
								<AmountInput
									value={landlord_fee}
									setValue={setlandlord_fee}
								/>
							</GridItem>
							<GridItem>
								<label>Africartz Fee</label>
								<AmountInput
									value={africartz_fee}
									setValue={setafricartz_fee}
									disabled
								/>
							</GridItem>
							<GridItem>
								<label>Caution fee</label>
								<AmountInput
									value={caution_fee}
									setValue={setcaution_fee}
								/>
							</GridItem>

							<GridItem>
								<FormControl
									display="flex"
									alignItems="center"
									mt={"15px"}
									mb={"5px"}
								>
									<Switch
										isChecked={status === "available"}
										onChange={() => setstatus("available")}
										id={"switch-label-available"}
										colorScheme={"orange"}
									/>
									<FormLabel
										htmlFor={"switch-label-available"}
										mb="0"
										ml={"9px"}
									>
										Available
									</FormLabel>
								</FormControl>
								<FormControl
									display="flex"
									alignItems="center"
									mb={"5px"}
								>
									<Switch
										isChecked={status === "booked"}
										onChange={() => setstatus("booked")}
										id={"switch-label-booked"}
										colorScheme={"orange"}
									/>
									<FormLabel
										htmlFor={"switch-label-booked"}
										mb="0"
										ml={"9px"}
									>
										Booked
									</FormLabel>
								</FormControl>
							</GridItem>

							<GridItem>
								<label>Address</label>
								<Input
									size="md"
									value={address}
									style={inputStyles}
									onChange={(x) =>
										setaddress(x?.target?.value)
									}
								/>
							</GridItem>
							<GridItem>
								<label>Location</label>
								<Input
									size="md"
									value={location}
									style={inputStyles}
									onChange={(x) =>
										setlocation(x?.target?.value)
									}
								/>
							</GridItem>
							<GridItem>
								<label>State</label>
								<SelectDropdown
									value={state}
									selected={state}
									handleSelect={(value) => setstate(value)}
									options={stateOptions}
									isMulti={false}
								/>
							</GridItem>

							<GridItem>
								<label htmlFor="">Description</label>
								<Textarea
									value={description}
									onChange={(e) =>
										setdescription(e.target.value)
									}
									placeholder=""
									size="sm"
								/>
							</GridItem>
							<GridItem>
								<ToggleInput
									label="Photo shoot fee"
									isVisible={photo_shoot_fee_visible}
									setVisible={setphoto_shoot_fee_visible}
									value={photo_shoot_fee}
									handleChange={setphoto_shoot_fee}
								/>
								<ToggleInput
									label="Movie shoot fee"
									isVisible={movie_shoot_fee_visible}
									setVisible={setmovie_shoot_fee_visible}
									value={movie_shoot_fee}
									handleChange={setmovie_shoot_fee}
								/>
								<ToggleInput
									label="Party fee"
									isVisible={birthday_fee_visible}
									setVisible={setbirthday_fee_visible}
									value={birthday_fee}
									handleChange={setbirthday_fee}
								/>
							</GridItem>

							<GridItem>
								<ToggleSymbols
									label="No Pets"
									value={photo_shoot_fee}
									handleChange={setphoto_shoot_fee}
								/>
								<ToggleSymbols
									label="No Smoking"
									value={movie_shoot_fee}
									handleChange={setmovie_shoot_fee}
								/>
								<ToggleSymbols
									label="No Partying"
									value={birthday_fee}
									handleChange={setbirthday_fee}
								/>
								<ToggleSymbols
									label="No Subleting"
									value={birthday_fee}
									handleChange={setbirthday_fee}
								/>
							</GridItem>
						</Grid>

						<FormControl>
							<Button
								type="submit"
								isLoading={loading}
								fontSize="12px"
								fontWeight="medium"
								borderRadius={"100px"}
								px={6}
								w="max"
								mt={8}
								bg="#de9301"
								_hover="none"
								_active={{
									bg: "white",
									transform: "none",
									borderColor: "transparent",
								}}
								_focus={{
									boxShadow: "none",
								}}
								color="white"
							>
								Save Apartment
							</Button>
						</FormControl>
					</form>
				</CardBody>
			</Card>

			{/* PICTURES */}
			<Card p="16px" w="100%" my={{ base: "120px", md: "75px" }}>
				<CardBody flexDirection={"column"}>
					<Text size={"lg"} fontWeight={"bold"}>
						Apartment Pictures
					</Text>
					<aside
						style={{
							display: "flex",
							flexFlow: "wrap",
							marginTop: "16px",
						}}
					>
						{pictures?.map((x, i) => (
							<ApartmentPicture
								key={i}
								data={x}
								default={x?.id === defaultpicture?.id}
							/>
						))}
					</aside>
				</CardBody>
			</Card>

			<Card p="16px" w="100%" my={{ base: "120px", md: "75px" }}>
				<CardBody flexDirection={"column"}>
					<Text size={"lg"} fontWeight={"bold"}>
						Upload Apartment Pictures
					</Text>
					<PushApartmentPictures apartmentId={id} />
				</CardBody>
			</Card>

			{/* VIDEO */}

			<Card p="16px" w="100%" my={{ base: "120px", md: "75px" }}>
				<CardBody flexDirection={"column"}>
					<Text size={"lg"} fontWeight={"bold"}>
						Apartment Videos
					</Text>
					<aside
						style={{
							display: "flex",
							flexFlow: "wrap",
							marginTop: "16px",
						}}
					>
						{videos?.map((x, i) => (
							<ApartmentVideo
								key={i}
								data={x}
								// default={x?.id === defaultVideo?.id}
							/>
						))}
					</aside>
				</CardBody>
			</Card>

			<Card p="16px" w="100%" my={{ base: "120px", md: "75px" }}>
				<CardBody flexDirection={"column"}>
					<Text size={"lg"} fontWeight={"bold"}>
						Upload Apartment Videos
					</Text>
					<PushApartmentVideos apartmentId={id} />
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default ApartmentDetails;

const ApartmentPicture = (props) => {
	const {
		data: { id, apartment_id, fullPath },
	} = props;

	const [loading, setLoading] = useState(false);
	const { token } = useContext(GlobalContext);

	const notify = useNotifier();

	const setAsDefault = () => {
		setLoading(true);
		const handleSuccess = (_res) => {
			notify("", "Done", "success");
			setLoading(!true);
		};

		const handleError = (_err) => {
			setLoading(!true);
			notify("Failed", "Could not set picture as default", "error");
		};

		fetchAPI(
			SetDefaultApartmentPictureAPI + apartment_id + "/" + id,
			handleSuccess,
			handleError,
			token
		);
	};

	const trash = () => {
		let proceed = window.confirm(
			"Are you sure you want to delete this picture?"
		);

		if (proceed) {
			setLoading(true);

			const handleSuccess = (_res) => {
				notify("", "Deleted", "info");
				setLoading(!true);
			};

			const handleError = (_err) => {
				setLoading(!true);
				notify("Failed", "Could not delete picture", "error");
			};

			fetchAPI(
				DeleteApartmentPictureAPI + id,
				handleSuccess,
				handleError,
				token
			);
		}
	};

	return (
		<div
			style={{
				display: "inline-flex",
				borderRadius: "2px",
				border: "1px solid rgb(234, 234, 234)",
				marginBottom: "8px",
				marginRight: "8px",
				width: "fit-content",
				maxWidth: "100px",
				height: "100px",
				padding: "4px",
				boxSizing: "border-box",
			}}
		>
			{!props?.default && (
				<button
					style={{ position: "absolute" }}
					type="button"
					onClick={setAsDefault}
					title="Set as default picture"
					className=""
				>
					<svg
						style={{ height: "1.5rem" }}
						class=""
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 384 512"
					>
						<path
							fill="#ffd43b"
							d="M32 32C32 14.3 46.3 0 64 0H320c17.7 0 32 14.3 32 32s-14.3 32-32 32H290.5l11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3H32c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64H64C46.3 64 32 49.7 32 32zM160 384h64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V384z"
						></path>
					</svg>
				</button>
			)}

			{props?.default && (
				<span
					style={{
						background: "aliceblue",
						padding: ".1rem",
						borderRadius: ".5rem",
						position: "absolute",
						fontSize: ".6rem",
					}}
				>
					DEFAULT
				</span>
			)}

			{loading && (
				<Spinner
					color="orange"
					style={{
						position: "absolute",
						marginLeft: 35,
						marginTop: 30,
					}}
				/>
			)}

			<div
				style={{ display: "flex", minWidth: "0px", overflow: "hidden" }}
			>
				<img
					src={fullPath}
					style={{ display: "block", width: "100%", height: "100%" }}
					alt=""
				/>
			</div>

			<button
				style={{ position: "absolute", bottom: 20 }}
				onClick={trash}
				title="Delete Picture"
			>
				<svg
					style={{ height: "1.5rem" }}
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
						clip-rule="evenodd"
					></path>
				</svg>
			</button>
		</div>
	);
};

const ApartmentVideo = (props) => {
	const {
		data: { id, apartment_id, fullPath },
	} = props;

	const [videoLoading, setVideoLoading] = useState(false);
	const { token } = useContext(GlobalContext);

	const notify = useNotifier();

	const trash = () => {
		let proceed = window.confirm(
			"Are you sure you want to delete this video?"
		);

		if (proceed) {
			setVideoLoading(true);

			const handleSuccess = (_res) => {
				notify("", "Deleted", "info");
				setVideoLoading(!true);
			};

			const handleError = (_err) => {
				setVideoLoading(!true);
				notify("Failed", "Could not delete video", "error");
			};

			fetchAPI(
				DeleteApartmentVideoAPI + id,
				handleSuccess,
				handleError,
				token
			);
		}
	};

	return (
		<div
			style={{
				display: "inline-flex",
				borderRadius: "2px",
				border: "1px solid rgb(234, 234, 234)",
				marginBottom: "8px",
				marginRight: "8px",
				width: "fit-content",
				maxWidth: "200px",
				height: "200px",
				padding: "4px",
				boxSizing: "border-box",
			}}
		>
			{videoLoading && (
				<Spinner
					color="orange"
					style={{
						position: "absolute",
						marginLeft: 35,
						marginTop: 30,
					}}
				/>
			)}

			<div
				style={{ display: "flex", minWidth: "0px", overflow: "hidden" }}
			>
				<video
					src={fullPath}
					controls
					style={{ display: "block", width: "100%", height: "100%" }}
				/>
			</div>

			<button
				style={{ position: "absolute", bottom: 20 }}
				onClick={trash}
				title="Delete Video"
				className=""
			>
				<svg
					style={{ height: "1.5rem" }}
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
						clip-rule="evenodd"
					></path>
				</svg>
			</button>
		</div>
	);
};
