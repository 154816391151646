import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Flex,
	FormControl,
	IconButton,
	Input,
	Spinner,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "../../../Context";

import { StaffAPI, ToggleStaffAPI } from "../../../Endpoints.jsx";
import { fetchAPI } from "../../../utils/fetchAPI";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FaEye } from "react-icons/fa";
import { IoReloadSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "recharts";
import useNotifier from "../../../hooks/useNotifier.jsx";
import ManualRequests from "./ManualRequests.jsx";

const Index = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [staff, setStaff] = useState([]);

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res?.status === "success") {
				setStaff(_res?.staff);
			} else {
				notify("Failed", "Could not get Staffs data", "error");
			}
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get  Staffs data", "error");
		};

		fetchAPI(StaffAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	const toggleStatus = (id) => {
		const handleRes = (_res) => {
			if (_res?.status === "success") {
				notify("Success", _res?.message, "success");
				fetchData();
			} else {
				let errors = _res?.errors;

				if (errors && Object.keys(errors).length > 0) {
					for (let err in errors) {
						notify(errors[`${err}`][0], "", "error");
					}
				} else {
					notify("Failed", _res?.message, "error");
				}
			}
		};

		const catchErr = (_err) => {
			let errors = _err?.errors;

			if (errors && Object.keys(errors).length > 0) {
				for (let err in errors) {
					notify(errors[`${err}`][0], "", "error");
				}
			} else {
				notify("Failed", _err?.message, "error");
			}
		};

		fetchAPI(ToggleStaffAPI + id, handleRes, catchErr, token);
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Tabs isFitted variant="enclosed">
					<TabList mb="1em">
						<Tab
							fontSize={"medium"}
							color={"#de9301"}
							fontWeight={"semibold"}
						>
							Manual Booking
						</Tab>
						<Tab
							fontSize={"medium"}
							color={"#de9301"}
							fontWeight={"semibold"}
						>
							Africartz fee Price Setting
						</Tab>
						<Flex gap="4">
							<Tooltip label="Reload">
								<IconButton
									onClick={() => fetchData()}
									aria-label="Reload"
									icon={<IoReloadSharp />}
								/>
							</Tooltip>
						</Flex>
					</TabList>
					<TabPanels>
						<TabPanel>
							<ManualRequests />
						</TabPanel>
						{/* /  */}
						<TabPanel>
							<Card p="16px" w={{ md: "70%", sm: "100%" }}>
								<CardBody
									display={"block"}
									style={{ flexDirection: "row" }}
								>
									<form>
										<Stack spacing={6} px={6} py={8}>
											<Stack spacing={0}>
												<label>
													20,0000 - 99,999
													=======6,000
												</label>
												<Input size="md" />
											</Stack>

											<Stack spacing={0}>
												<label>
													100,000- 199,999
													=======12,000
												</label>
												<Input size="md" />
											</Stack>

											<Stack spacing={0}>
												<label>
													200,000 -
													299,999=======20,000
												</label>
												<Input size="md" />
											</Stack>

											<Stack spacing={0}>
												<label>
													300,000 - 399,999
													======28,000
												</label>
												<Input size="md" />
											</Stack>

											<Stack spacing={0}>
												<label>
													400,000- 499,999
													=======35,000
												</label>
												<Input size="md" />
											</Stack>
											<Stack spacing={0}>
												<label>
													500,000 -
													799,999======40,000
												</label>
												<Input size="md" />
											</Stack>
											<Stack spacing={0}>
												<label>
													800,000-1000,000
													======40,000
												</label>
												<Input size="md" />
											</Stack>

											<FormControl>
												<Button
													type="submit"
													isLoading={loading}
													fontSize="12px"
													fontWeight="medium"
													borderRadius={"100px"}
													px={6}
													w="max"
													mt={4}
													bg="#de9301"
													_hover="none"
													_active={{
														bg: "white",
														transform: "none",
														borderColor:
															"transparent",
													}}
													_focus={{
														boxShadow: "none",
													}}
													color="white"
												>
													Upload
												</Button>
											</FormControl>
										</Stack>
									</form>
								</CardBody>
							</Card>
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
		</Flex>
	);
};

export default Index;
