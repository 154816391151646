import React, { useState, useContext, useCallback, useEffect } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { Button, Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import { fetchAPI, fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import {
	ViewInspectionAPI,
	ProcessInspectAPI,
	SetDefaultApartmentPictureAPI,
} from "../../../Endpoints.jsx";
import { useParams } from "react-router-dom";


const InspectionDetails = () => {
	const { id } = useParams();

	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	const [inspectionButton, setInspectionButton] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isButtonClicked, setIsButtonClicked] = useState(false);

	const AcceptInspectionButton = () => {
		setInspectionButton(true);
		setIsButtonDisabled(true);
		fetch(`${ProcessInspectAPI}/${id}`, {
			method: "GET",
			headers: new Headers({
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			}),
		})
			.then((res) => res.json())
			.then((resp) => {
				setInspectionButton(false);

				if (resp.status === "failed") {
					setIsButtonDisabled(false);
					return [
						// console.log(resp.message),
						notify("Cannot process Inspection"),
					];
				}
				setIsButtonClicked(true);
				notify(resp.message);
			})
			.catch((error) => {
				setInspectionButton(false);
				setIsButtonDisabled(false);

				console.log(error);
				notify("Something went wrong");
			});
	};

	const [inspection, setInspection] = useState({
		apartment: {
			defaultPicture: null,
			pictures: [],
		},
		name: "",
		address: "",
		inspection_date: "",
		landlord_name: "",
		username: "",
		email: "",
		first_name: "",
		last_name: "",
		phone: "",
		status: "",
	});

	const notify = useNotifier();
	const [pictures, setpictures] = useState([]);
	const [defaultpicture, setdefaultpicture] = useState();

	const fetchLandlordDetails = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Success", _res?.message, "success");
			const { request } = _res;
			setInspection({
				apartment: {
					defaultPicture: request.apartment.defaultPicture,
					pictures: request.apartment.pictures,
				},
				name: request.apartment.name,
				address: request.apartment.address,
				inspection_date: request.inspection_date,
				landlord_fir_name: request.landlord.first_name,
				landlord_las_name: request.landlord.last_name,

				username: request.user.username,
				email: request.user.email,
				first_name: request.user.first_name,
				last_name: request.user.last_name,
				phone: request.user.phone,
				status: request.user.status,
			});
			setdefaultpicture(request?.apartment?.defaultPicture);
			setpictures(request?.apartment?.pictures);
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: ViewInspectionAPI + id,
			method: "GET",
			token,
			handleSuccess,
			handleError,
		});
		setLoading(false);
	}, [id, notify, token]);

	useEffect(() => {
		fetchLandlordDetails();
		// eslint-disable-next-line
	}, []);

	return (
		<Card p="16px" pt={{ base: "120px", md: "75px" }}>
			<CardBody display={"block"}>
				{loading ? (
					<Spinner />
				) : (
					<React.Fragment>
						<Stack paddingBottom={"4"}>
							<Text fontSize="2xl" fontWeight={"bold"}>
								Inspection Details
							</Text>

							<Text fontSize="lg" fontWeight={"semibold"}>
								{" "}
								Apartment
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Name: {inspection.name}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Address: {inspection.address}
							</Text>
						</Stack>
						{/*  */}

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Inspection Date/Time
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Date/Time:{inspection.inspection_date}
							</Text>
						</Stack>
						{/*  */}
						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Super Agent
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Super Agent Name:
								{inspection.landlord_fir_name}
								&nbsp; {inspection.landlord_las_name}
							</Text>
						</Stack>
						{/*  */}
						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Customer
							</Text>

							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Customer Name:{inspection.first_name} &nbsp;{" "}
								{inspection.last_name}
							</Text>

							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Customer Phone No:{inspection.phone}
							</Text>
						</Stack>
						{!isButtonClicked && (
							<Button
								onClick={AcceptInspectionButton}
								type="submit"
								isLoading={inspectionButton}
								fontSize="12px"
								fontWeight="medium"
								borderRadius={"100px"}
								px={6}
								w="max"
								bg="#de9301"
								_hover="none"
								_active={{
									bg: "white",
									transform: "none",
									borderColor: "transparent",
								}}
								_focus={{
									boxShadow: "none",
								}}
								color="white"
								disabled={isButtonDisabled}
							>
								Process
							</Button>
						)}
					</React.Fragment>
				)}
			</CardBody>

			<CardBody flexDirection={"column"}>
				<Text size={"lg"} fontWeight={"semibold"} pt={20}>
					Pictures
				</Text>
				<aside
					style={{
						display: "flex",
						flexFlow: "wrap",
						marginTop: "16px",
					}}
				>
					{pictures?.map((x, i) => (
						<ApartmentPicture
							key={i}
							data={x}
							default={x?.id === defaultpicture?.id}
						/>
					))}
				</aside>
			</CardBody>
		</Card>
	);
};

export default InspectionDetails;

const ApartmentPicture = (props) => {
	const {
		data: { id, apartment_id, fullPath },
	} = props;

	const [loading, setLoading] = useState(false);
	const { token } = useContext(GlobalContext);

	const notify = useNotifier();

	const setAsDefault = () => {
		setLoading(true);
		const handleSuccess = (_res) => {
			notify("", "Done", "success");
			setLoading(!true);
		};

		const handleError = (_err) => {
			setLoading(!true);
			notify("Failed", "Could not set picture as default", "error");
		};

		fetchAPI(
			SetDefaultApartmentPictureAPI + apartment_id + "/" + id,
			handleSuccess,
			handleError,
			token
		);
	};

	return (
		<div
			style={{
				display: "inline-flex",
				borderRadius: "2px",
				border: "1px solid rgb(234, 234, 234)",
				marginBottom: "8px",
				marginRight: "8px",
				width: "fit-content",
				maxWidth: "100px",
				height: "100px",
				padding: "4px",
				boxSizing: "border-box",
			}}
		>
			{!props?.default && (
				<button
					style={{ position: "absolute" }}
					type="button"
					onClick={setAsDefault}
					title="Set as default picture"
					className=""
				></button>
			)}

			{props?.default && (
				<span
					style={{
						background: "aliceblue",
						padding: ".1rem",
						borderRadius: ".5rem",
						position: "absolute",
						fontSize: ".6rem",
					}}
				>
					DEFAULT
				</span>
			)}

			{loading && (
				<Spinner
					color="orange"
					style={{
						position: "absolute",
						marginLeft: 35,
						marginTop: 30,
					}}
				/>
			)}

			<div style={{ display: "flex", minWidth: "0px", overflow: "hidden" }}>
				<img src={fullPath} style={{ display: "block", width: "100%", height: "100%" }} alt="" />
			</div>
		</div>
	);
};
