import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
// Chakra imports
import {
	ButtonGroup,
	Flex,
	IconButton,
	SimpleGrid,
	Spinner,
	Text,
	Tooltip,
} from "@chakra-ui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";

import { AiFillRead } from "react-icons/ai";
import { BsBook, BsFillPeopleFill } from "react-icons/bs";
import { IoReloadSharp } from "react-icons/io5";

import dayjs from "dayjs";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context.jsx";
import { GetManualRequestsAPI, RequestsAPI } from "../../../Endpoints.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchAPI } from "../../../utils/fetchAPI.jsx";
import { numberWithCommas } from "../../../utils/index.js";
import MiniStatistics from "../Landlord/components/MiniStatistics.js";

const ManualRequests = () => {
	const { token } = useContext(GlobalContext);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [totalrequest, setTotalRequest] = useState(0);
	const [totalAccepted, setTotalAccepted] = useState(0);
	const [totalPending, setTotalPending] = useState(0);

	const [requests, setRequests] = useState([]);

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res?.status === "success") {
				setTotalRequest(Number(_res.total));
				setTotalAccepted(Number(_res.approved));
				setTotalPending(Number(_res.pending));

				setRequests(_res?.requests);
			} else {
				notify("Failed", "Could not get Requests data", "error");
			}

			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get Requests data", "error");
		};

		fetchAPI(GetManualRequestsAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex flexDirection="column" pt={{ base: "0px", md: "0px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Fragment>
					<SimpleGrid
						columns={{ sm: 1, md: 2, xl: 4 }}
						spacing="24px"
					>
						<MiniStatistics
							title={"Total Manual Reservations"}
							amount={numberWithCommas(totalrequest)}
							icon={BsFillPeopleFill}
						/>
						<MiniStatistics
							title={"Pending"}
							amount={numberWithCommas(totalPending)}
							icon={AiFillRead}
						/>
						<MiniStatistics
							title={"Approved"}
							amount={numberWithCommas(totalAccepted)}
							icon={BsBook}
						/>


					</SimpleGrid>

					<Card p="16px" mt="20px" w="100%">
						<CardHeader>
							<Flex
								justify="space-between"
								align="center"
								minHeight="60px"
								w="100%"
							>
								<Text
									fontSize="lg"
									color={"#de9301"}
									fontWeight="bold"
								>
									Manual Reservation Requests
								</Text>

								<Tooltip label="Reload">
									<IconButton
										icon={<IoReloadSharp />}
										onClick={() => fetchData()}
									/>
								</Tooltip>
							</Flex>
						</CardHeader>

						<CardBody
							display={"block"}
							style={{ flexDirection: "row" }}
						>
							<DataTable
								value={requests}
								paginator
								rows={5}
								rowsPerPageOptions={[5, 10, 25, 50]}
							>
								{/* <Column
									sortable
									field="id"
									filter
									header="#"
									style={{ width: "25%" }}
								></Column> */}
								<Column
									sortable
									field="name"
									filter
									header="Apartment"
									body={(row) => row?.apartment?.name || "N/A"}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="email"
									filter
									header="User"
									body={(row) => row?.user?.email || "N/A"}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="landlord"
									filter
									header="Landlord"
									body={(row) =>
										row?.landlord.first_name +
										" " +
										row?.landlord.last_name
									}
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field="status"
									filter
									header="Status"
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="created_at"
									filter
									header="Created"
									body={(row) => (
										<Text>
											{dayjs(row?.created_at).format(
												"ddd D MMM YYYY"
											)}
										</Text>
									)}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="check_in"
									filter
									header="Check-In"
									body={(row) => (
										<Text>
											{dayjs(row?.check_in).format(
												"ddd D MMM YYYY"
											)}
										</Text>
									)}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="check_out"
									filter
									header="Check-Out"
									body={(row) => (
										<Text>
											{dayjs(row?.check_out).format(
												"ddd D MMM YYYY"
											)}
										</Text>
									)}
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field=""
									header="Action"
									body={(row) => (
										<ButtonGroup
											size="sm"
											isAttached
											variant="ghost"
										>
											<Tooltip label="View">
												<IconButton
													colorScheme="black"
													onClick={() =>
														navigate(
															`/admin/manual/reservation/${row?.id}`
														)
													}
													aria-label="View"
													icon={<FaEye />}
												/>
											</Tooltip>
										</ButtonGroup>
									)}
									style={{ width: "25%" }}
								></Column>
							</DataTable>
						</CardBody>
					</Card>
				</Fragment>
			)}
		</Flex>
	);
};

export default ManualRequests;
