import React, { useState, useContext, useEffect, useCallback } from "react";
import GlobalContext from "../../../Context.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { Button, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import { fetchAPI, fetchProtectedResource } from "../../../utils/fetchAPI.jsx";
import {
	ViewRequestsDetailsAPI,
	AcceptRequests,
	DeclinedRequests,
	SetDefaultApartmentPictureAPI,
} from "../../../Endpoints.jsx";
import { useParams } from "react-router-dom";
import ReceiptDropBox from "./ReceiptDropBox.jsx";
import { numberWithCommas } from "../../../utils/index.js";


const ReservationDetails = () => {
	const { id } = useParams();

	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	const [reservation, setReservation] = useState({});

	const [acceptButton, setAcceptButton] = useState(false);
	const [declineButton, setDeclineButton] = useState(false);

	const [pictures, setpictures] = useState([]);
	const [defaultpicture, setdefaultpicture] = useState();

	const notify = useNotifier();

	const AcceptButton = () => {
		setAcceptButton(true);
		fetch(`${AcceptRequests}/${id}`, {
			method: "GET",
			headers: new Headers({
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			}),
		})
			.then((res) => res.json())
			.then((resp) => {
				setAcceptButton(false);

				if (resp.status === "failed") {
					return [
						console.log(resp.message),
						notify("Invalid reservation request sent"),
					];
				}

				notify(resp.message);
			})
			.catch((error) => {
				setAcceptButton(false);

				console.log(error);
				notify("Something went wrong");
			});
	};

	const DeclineButton = () => {
		setDeclineButton(true);
		fetch(`${DeclinedRequests}/${id}`, {
			method: "GET",
			headers: new Headers({
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			}),
		})
			.then((res) => res.json())
			.then((resp) => {
				setDeclineButton(false);

				if (resp.status === "failed") {
					return [
						console.log(resp.message),
						notify("Invalid reservation request sent"),
					];
				}

				notify(resp.message);
			})
			.catch((error) => {
				setDeclineButton(false);

				console.log(error);
				notify("Something went wrong");
			});
	};

	const fetchLandlordDetails = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			notify("Success", _res?.message, "success");
			const { request } = _res;
			setReservation({
				apartment: {
					defaultPicture: request.apartment.defaultPicture,
					pictures: request.apartment.pictures,
				},
				name: request.apartment.name,
				address: request.apartment.address,
				reservation_type: request.reservation_type,
				check_in: request.check_in,
				check_out: request.check_out,

				landlord_fee: request.apartment.landlord_fee,
				photo_shoot_fee: request.apartment.photo_shoot_fee,
				movie_shoot_fee: request.apartment.movie_shoot_fee,
				birthday_fee: request.apartment.birthday_fee,

				africartz_fee: request.apartment.africartz_fee,
				caution_fee: request.apartment.caution_fee,

				first_name: request.user.first_name,
				last_name: request.user.last_name,
				phone: request.user.phone,

				first: request.landlord.first_name,
				phoneno: request.landlord.phone,
				status: request.status,
				reservationPrice: request.reservationPrice,
			});
			setdefaultpicture(request?.apartment?.defaultPicture);
			setpictures(request?.apartment?.pictures);
			setLoading(false);
		};

// 	export const usecases:any = {
// 	"Normal Stay": "landlord_fee",
// 	"Party": "birthday_fee",
// 	"Movie shoot": "movie_shoot_fee",
// 	"Photo shoot": "photo_shoot_fee",
// };
		const handleError = () => {
			setLoading(false);
			notify("Oppss...", "Check your network connection", "error");
		};

		fetchProtectedResource({
			url: ViewRequestsDetailsAPI + id,
			method: "GET",
			token,
			handleSuccess,
			handleError,
		});
	}, [id, notify, token]);

	useEffect(() => {
		fetchLandlordDetails();
		// eslint-disable-next-line
	}, []);

	
	const usecases = {
		"Normal Stay": "landlord_fee",
		"Party": "birthday_fee",
		"Movie shoot": "movie_shoot_fee",
		"Photo shoot": "photo_shoot_fee",
	};
	

	
	
	return (
		<Flex flexDirection={"row"} pt={{ base: "120px", md: "75px" }}>
			<Card p="16px" w={{ md: "100%", sm: "100%" }}>
				{!loading && (<>
					<CardBody display={"block"}>
						<Stack paddingBottom={"4"}>
							<Text fontSize="2xl" fontWeight={"bold"}>
								{" "}
								Reservation Details
							</Text>
							<Text fontSize="lg" fontWeight={"semibold"}>
								{" "}
								Apartment
							</Text>
							
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Name:{reservation.name}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Address:{reservation.address}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Reservation Type:{reservation.reservation_type}
							</Text>

							<Text fontSize="lg" fontWeight={"semibold"}>
								Reservation Status: {reservation?.status}
							</Text>
						</Stack>
						{/*  */}

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Reservation Dates
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Check In:{reservation.check_in}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Check Out:{reservation.check_out}
							</Text>
						</Stack>
						{/*  */}

						{/*  */}

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Super Agent
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Super Agent Name:{reservation.first}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Super Phone No:{reservation.phoneno}
							</Text>
						</Stack>
						{/*  */}
						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Customer
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Customer Name:{reservation.first_name} &nbsp;{" "}
								{reservation.last_name}
							</Text>

							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Customer Phone No:{reservation.phone}
							</Text>
						</Stack>

						<Stack paddingBottom={"4"}>
							<Text fontSize="lg" fontWeight={"semibold"}>
								Cost
							</Text>
							

							<Text
									fontSize="xl"
									fontWeight={"light"}
									color={"gray.500"}
								>
									{reservation.reservation_type} fee: ₦
									{numberWithCommas(reservation[usecases[reservation.reservation_type]])}
							</Text>

														
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Africartz fee: ₦
								{numberWithCommas(reservation.africartz_fee)}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
								Caution fee: ₦
								{numberWithCommas(reservation.caution_fee)}
							</Text>
							<Text
								fontSize="xl"
								fontWeight={"light"}
								color={"gray.500"}
							>
							
								Total: ₦
								{numberWithCommas(reservation.reservationPrice)}

							</Text>
							
						</Stack>

						
						{/* BUTTONS HERE */}
						{reservation?.status === "pending" && (
							<Flex
								gap={"4"}
								justifyItems={"center"}
								alignContent={"center"}
							>
								<Button
									onClick={AcceptButton}
									type="submit"
									isLoading={acceptButton}
									fontSize="12px"
									fontWeight="medium"
									borderRadius={"100px"}
									px={6}
									w="max"
									// mt={4}
									bg="#de9301"
									_hover="none"
									_active={{
										bg: "white",
										transform: "none",
										borderColor: "transparent",
									}}
									_focus={{
										boxShadow: "none",
									}}
									color="white"
								>
									Accept
								</Button>
								<Button
									type="submit"
									onClick={DeclineButton}
									isLoading={declineButton}
									fontSize="12px"
									fontWeight="medium"
									borderRadius={"100px"}
									px={6}
									w="max"
									bg="red"
									_hover="none"
									_active={{
										bg: "white",
										transform: "none",
										borderColor: "transparent",
									}}
									_focus={{
										boxShadow: "none",
									}}
									color="white"
								>
									Decline
								</Button>

								<ReceiptDropBox />
							</Flex>
						)}
					</CardBody>
					<CardBody flexDirection={"column"}>
						<Text size={"lg"} fontWeight={"semibold"}>
							Pictures
						</Text>
						<aside
							style={{
								display: "flex",
								flexFlow: "wrap",
								marginTop: "16px",
							}}
						>
							{pictures?.map((x, i) => (
								<ApartmentPicture
									key={i}
									data={x}
									default={x?.id === defaultpicture?.id}
								/>
							))}
						</aside>
					</CardBody>
				</>)}

				{loading && (<Spinner colorScheme="orange" size="lg" />)}
			</Card>
		</Flex>
	);
};

export default ReservationDetails;

const ApartmentPicture = (props) => {
	const {
		data: { id, apartment_id, fullPath },
	} = props;

	const [loading, setLoading] = useState(false);
	const { token } = useContext(GlobalContext);

	const notify = useNotifier();

	const setAsDefault = () => {
		setLoading(true);
		const handleSuccess = (_res) => {
			notify("", "Done", "success");
			setLoading(!true);
		};

		const handleError = (_err) => {
			setLoading(!true);
			notify("Failed", "Could not set picture as default", "error");
		};

		fetchAPI(
			SetDefaultApartmentPictureAPI + apartment_id + "/" + id,
			handleSuccess,
			handleError,
			token
		);
	};

	return (
		<div
			style={{
				display: "inline-flex",
				borderRadius: "2px",
				border: "1px solid rgb(234, 234, 234)",
				marginBottom: "8px",
				marginRight: "8px",
				width: "fit-content",
				maxWidth: "100px",
				height: "100px",
				padding: "4px",
				boxSizing: "border-box",
			}}
		>
			{!props?.default && (
				<button
					style={{ position: "absolute" }}
					type="button"
					onClick={setAsDefault}
					title="Set as default picture"
					className=""
				></button>
			)}

			{props?.default && (
				<span
					style={{
						background: "aliceblue",
						padding: ".1rem",
						borderRadius: ".5rem",
						position: "absolute",
						fontSize: ".6rem",
					}}
				>
					DEFAULT
				</span>
			)}

			{loading && (
				<Spinner
					color="orange"
					style={{
						position: "absolute",
						marginLeft: 35,
						marginTop: 30,
					}}
				/>
			)}

			<div style={{ display: "flex", minWidth: "0px", overflow: "hidden" }}>
				<img src={fullPath} style={{ display: "block", width: "100%", height: "100%" }} alt="" />
			</div>
		</div>
	);
};
