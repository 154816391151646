import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
// Chakra imports
import {
	Button,
	ButtonGroup,
	Flex,
	IconButton,
	SimpleGrid,
	Spinner,
	Text,
	Tooltip,
} from "@chakra-ui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";

import { AiFillRead } from "react-icons/ai";
import { IoReloadSharp } from "react-icons/io5";
import { TfiWrite } from "react-icons/tfi";

import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context";
import { ReferrersAPI } from "../../../Endpoints";
import useNotifier from "../../../hooks/useNotifier";
import { numberWithCommas } from "../../../utils";
import { fetchAPI } from "../../../utils/fetchAPI";
import MiniStatistics from "../Landlord/components/MiniStatistics";

const Referrers = () => {
	const { token } = useContext(GlobalContext);

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [referrersCount, setReferrersCount] = useState(0);
	const [referralsCount, setReferralsCount] = useState(0);
	const [referrers, setReferrers] = useState([]);

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res) {
				setReferrersCount(Number(_res.referrers_count));
				setReferralsCount(Number(_res.referrals_count));

				setReferrers(_res?.referrers);
			} else {
				notify("Failed", "Could not get Referrer Data", "error");
			}

			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get Referrer data", "error");
		};

		fetchAPI(ReferrersAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Fragment>
					<SimpleGrid
						columns={{ sm: 1, md: 2, xl: 3 }}
						spacing="24px"
					>
						<MiniStatistics
							title={"Total Referrals"}
							amount={numberWithCommas(referralsCount)}
							icon={TfiWrite}
						/>

						<MiniStatistics
							title={"Total Referrer"}
							amount={numberWithCommas(referrersCount)}
							icon={AiFillRead}
						/>
					</SimpleGrid>

					<Card p="16px" mt="20px" w="100%">
						<CardHeader>
							<Flex
								justify="space-between"
								align="center"
								minHeight="60px"
								w="100%"
							>
								<Text
									fontSize="lg"
									color={"#de9301"}
									fontWeight="bold"
								>
									Referrer
								</Text>
								<Flex gap="6">
									<Tooltip label="Reload">
										<IconButton
											icon={<IoReloadSharp />}
											onClick={() => fetchData()}
										/>
									</Tooltip>
									<Link to="/admin/referrer/New">
										<Button
											p={"8px 30px"}
											borderRadius={"5px"}
											variant={"solid"}
											color="#de9301"
											fontWeight="bold"
										>
											Add Refferer
										</Button>
									</Link>
								</Flex>
							</Flex>
						</CardHeader>

						<CardBody
							display={"block"}
							style={{ flexDirection: "row" }}
						>
							<DataTable
								value={referrers}
								paginator
								rows={5}
								rowsPerPageOptions={[5, 10, 25, 50]}
							>
								{/* <Column
									sortable
									field="id"
									filter
									header="#"
									style={{ width: "25%" }}
								></Column> */}
								<Column
									sortable
									field="referral_code"
									filter
									header="Referral code"
									body={(row) => row?.ref_code}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="referral_name"
									filter
									header="Referral Name"
									body={(row) =>
										row?.first_name + " " + row?.last_name
									}
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field="email"
									filter
									header="Email"
									body={(row) => row?.email}
									style={{ width: "25%" }}
								></Column>
								<Column
									sortable
									field="no_of_referrals"
									filter
									header="No of Referrals"
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field="phone"
									filter
									header="Phone"
									body={(row) => row?.phone}
									style={{ width: "25%" }}
								></Column>

								<Column
									sortable
									field=""
									header="Action"
									body={(row) => (
										<ButtonGroup
											size="sm"
											isAttached
											variant="ghost"
										>
											<Tooltip label="View">
												<IconButton
													colorScheme="black"
													onClick={() =>
														navigate(
															`/admin/referrer/${row?.id}`
														)
													}
													aria-label="View"
													icon={<FaEye />}
												/>
											</Tooltip>
										</ButtonGroup>
									)}
									style={{ width: "25%" }}
								></Column>
							</DataTable>
						</CardBody>
					</Card>
				</Fragment>
			)}
		</Flex>
	);
};

export default Referrers;
