import {
	Button,
	Card,
	Flex,
	IconButton,
	Spinner,
	Switch,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "../../../Context";

import { StaffAPI, ToggleStaffAPI } from "../../../Endpoints.jsx";
import { fetchAPI } from "../../../utils/fetchAPI";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IoReloadSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Tooltip } from "recharts";
import useNotifier from "../../../hooks/useNotifier.jsx";

const Index = () => {
	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);
	const [statusLoading, setStatusLoading] = useState(false);

	const [staff, setStaff] = useState([]);

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			if (_res?.status === "success") {
				setStaff(_res?.staff);
			} else {
				notify("Failed", "Could not get Staffs data", "error");
			}
			setLoading(false);
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get  Staffs data", "error");
		};

		fetchAPI(StaffAPI, handleSuccess, handleError, token);

		// eslint-disable-next-line
	}, [token]);

	const toggleStatus = (id) => {
		setStatusLoading(true);
		const handleRes = (_res) => {
			if (_res?.status === "success") {
				notify("Success", _res?.message, "success");
				fetchData();
			} else {
				let errors = _res?.errors;

				if (errors && Object.keys(errors).length > 0) {
					for (let err in errors) {
						notify(errors[`${err}`][0], "", "error");
					}
				} else {
					notify("Failed", _res?.message, "error");
				}
			}
			setStatusLoading(false);
		};

		const catchErr = (_err) => {
			let errors = _err?.errors;

			if (errors && Object.keys(errors).length > 0) {
				for (let err in errors) {
					notify(errors[`${err}`][0], "", "error");
				}
			} else {
				notify("Failed", _err?.message, "error");
			}
			setStatusLoading(false);
		};

		fetchAPI(ToggleStaffAPI + id, handleRes, catchErr, token);
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Tabs isFitted variant="enclosed">
					<TabList mb="1em">
						<Tab
							fontSize={"3xl"}
							color={"#de9301"}
							fontWeight={"bold"}
						>
							Staffs
						</Tab>
						<Flex gap="4">
							<Tooltip label="Reload">
								<IconButton
									onClick={() => fetchData()}
									aria-label="Reload"
									icon={<IoReloadSharp />}
								/>
							</Tooltip>
							<Link to="/admin/staffs/New">
								<Button
									p={"8px 30px"}
									borderRadius={"5px"}
									variant={"solid"}
									color="#de9301"
									fontWeight="bold"
								>
									Add Staff
								</Button>
							</Link>
						</Flex>
					</TabList>
					<TabPanels>
						{/* /  */}
						<TabPanel>
							<Card
								display={"block"}
								style={{ flexDirection: "row" }}
							>
								<DataTable
									value={staff}
									paginator
									rows={5}
									rowsPerPageOptions={[5, 10, 25, 50]}
								>
									{/* <Column
									sortable
									field="id"
									filter
									header="#"
									style={{ width: "25%" }}
								></Column> */}
									<Column
										sortable
										field="name"
										filter
										header="Staff Name"
										body={(row) => row.name ?? "N/A"}
										style={{ width: "25%" }}
									></Column>
									<Column
										sortable
										field="email"
										filter
										header="Email"
										body={(row) => row.email ?? "N/A"}
										style={{ width: "25%" }}
									></Column>

									{/* <Column
									sortable
									field="phone"
									filter
									header="Phone"
									body={(row) => <> {row?.phone} </>}
									style={{ width: "25%" }}
								></Column> */}
									<Column
										sortable
										field="role"
										filter
										header="Role"
										body={(row) => <> {row?.role} </>}
										style={{ width: "25%" }}
									></Column>
									<Column
									sortable
									field="department"
									filter
									header="Department"
									body={(row) => <> {row?.phone} </>}
									style={{ width: "25%" }}
								></Column>
									<Column
									sortable
									field="accountNo"
									filter
									header="Account Number"
									body={(row) => <> {row?.accountNo} </>}
									style={{ width: "25%" }}
								></Column>
									<Column
									sortable
									field="bank"
									filter
									header="Bank"
									body={(row) => <> {row?.bank} </>}
									style={{ width: "25%" }}
								></Column>
									<Column
										sortable
										field="created_at"
										filter
										header="Date joined"
										body={(row) => <> {row?.created_at} </>}
										style={{ width: "25%" }}
									></Column>
									<Column
										sortable
										field="status"
										filter
										header="Status"
										body={(row) => <> {row?.status} </>}
										style={{ width: "25%" }}
									></Column>

									<Column
										sortable
										field=""
										header="Action"
										body={(row) => (
											<Switch
												disabled={statusLoading}
												onChange={() => {
													console.log(row, "row");
													toggleStatus(row?.id);
												}}
												isChecked={
													row.status === "active"
														? true
														: false
												}
											/>
										)}
										style={{ width: "25%" }}
									></Column>
								</DataTable>
							</Card>
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
		</Flex>
	);
};

export default Index;
