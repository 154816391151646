import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
// Chakra imports
import {
	Box,
	Flex,
	SimpleGrid,
	Spinner,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";

import { BsFillPeopleFill } from "react-icons/bs";
import { FaGraduationCap } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { TfiWrite } from "react-icons/tfi";

import GlobalContext from "../../../Context.jsx";
import { DashboardAPI } from "../../../Endpoints.jsx";
import useNotifier from "../../../hooks/useNotifier.jsx";
import { fetchAPI } from "../../../utils/fetchAPI.jsx";
import { numberWithCommas } from "../../../utils/index.js";
import MiniStatistics from "../Landlord/components/MiniStatistics.js";

const AdminPaymentPage = () => {
	const textColor = useColorModeValue("gray.700", "white");

	const { token } = useContext(GlobalContext);
	const [loading, setLoading] = useState(false);

	const [inspection, setInspection] = useState(0);
	const [booking, setBooking] = useState(0);
	const [reservation, setReservation] = useState(0);
	const [sales, setSales] = useState("");
	const [profit, setProfit] = useState("");

	// History
	const [chartData, setChartData] = useState([]);
	const [chartOptions, setChartOptions] = useState({});

	const notify = useNotifier();

	const fetchData = useCallback(() => {
		setLoading(true);

		const handleSuccess = (_res) => {
			// if (_res && _res?.status === "success") {
			if (_res) {
				setInspection(Number(_res.total_inspections));
				setBooking(Number(_res.total_bookings));
				setReservation(Number(_res.total_reservations));
				setSales(_res.total_sales);
				setProfit(_res.profit);
			} else {
				notify("Failed", "Could not get data", "error");
			}

			setLoading(false);
		};

		const handleHistorySuccess = (_res) => {
			console.log(_res, "res....");

			if (_res?.status === "success") {
				// if (_res) {
				const lineChartOptions = {
					chart: {
						toolbar: {
							show: !false,
						},
					},
					tooltip: {
						theme: "dark",
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						curve: "smooth",
					},
					xaxis: {
						// type: "datetime",
						type: "string",
						categories: _res?.periods,
						labels: {
							style: {
								colors: "#c8cfca",
								fontSize: "12px",
							},
						},
					},
					yaxis: {
						labels: {
							style: {
								colors: "#c8cfca",
								fontSize: "12px",
							},
						},
					},
					legend: {
						show: !false,
					},
					grid: {
						strokeDashArray: 5,
					},
					fill: {
						type: "gradient",
						gradient: {
							shade: "light",
							type: "vertical",
							shadeIntensity: 0.5,
							gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
							inverseColors: true,
							opacityFrom: 0.8,
							opacityTo: 0,
							stops: [],
						},
						colors: ["#4FD1C5", "#2D3748", "#7484FD", "#f700ff"],
					},
					colors: ["#4FD1C5", "#2D3748", "#7484FD", "#f700ff"],
				};

				const lineChartData = [
					{
						name: "Total",
						data: _res?.total,
					},
					{
						name: "Subscriptions",
						data: _res?.subscriptions,
					},
					{
						name: "Course",
						data: _res?.courses,
					},
					{
						name: "Exams",
						data: _res?.exams,
					},
				];

				setChartData(lineChartData);
				setChartOptions(lineChartOptions);
			} else {
				notify("Failed", "Could not get data", "error");
			}
		};

		const handleError = () => {
			setLoading(false);
			notify("Failed", "Could not get data", "error");
		};

		fetchAPI(DashboardAPI, handleSuccess, handleError, token);
		// fetchAPI(AdminPaymentsHistoryAPI, handleHistorySuccess, token);

		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const data = [
		{
			name: "Page A",
			uv: 4000,
			pv: 2400,
		},
		{
			name: "Page B",
			uv: 3000,
			pv: 1398,
		},
		{
			name: "Page C",
			uv: 2000,
			pv: 9800,
		},
		{
			name: "Page D",
			uv: 2780,
			pv: 3908,
		},
		{
			name: "Page E",
			uv: 1890,
			pv: 4800,
		},
		{
			name: "Page F",
			uv: 2390,
			pv: 3800,
		},
		{
			name: "Page G",
			uv: 3490,
			pv: 4300,
		},
	];

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			{loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "30rem",
						width: "100%",
					}}
				>
					<Spinner />
				</div>
			) : (
				<Fragment>
					<SimpleGrid
						columns={{ sm: 1, md: 2, xl: 5 }}
						spacing="24px"
					>
						<MiniStatistics
							title={"Inspection"}
							amount={numberWithCommas(inspection)}
							icon={BsFillPeopleFill}
						/>
						<MiniStatistics
							title={"Reservations"}
							amount={numberWithCommas(reservation)}
							icon={GiMoneyStack}
						/>
						<MiniStatistics
							title={"Bookings"}
							amount={numberWithCommas(booking)}
							icon={FaGraduationCap}
						/>
						<MiniStatistics
							title={"Sales"}
							amount={numberWithCommas(sales)}
							icon={TfiWrite}
						/>
						<MiniStatistics
							title={"Profit"}
							amount={numberWithCommas(profit)}
							icon={TfiWrite}
						/>
					</SimpleGrid>

					<Flex
						justify="space-between"
						align="center"
						mb="1rem"
						w="100%"
						pt={{ base: "120px", md: "25px" }}
					>
						<Card
							p="28px 10px 16px 0px"
							mb={{ sm: "26px", lg: "0px" }}
						>
							<CardHeader mb="20px" pl="22px">
								<Flex direction="column" alignSelf="flex-start">
									<Text
										fontSize="lg"
										color={textColor}
										fontWeight="bold"
										mb="6px"
									>
										Payments History
									</Text>
								</Flex>
							</CardHeader>
							<Box w="100%" h={{ sm: "300px" }} ps="8px">
								<ReactApexChart
									options={chartOptions}
									series={chartData}
									type="area"
									width="100%"
									height="100%"
								/>
							</Box>

							{/* <Box>
								<BarChart width={730} height={250} data={data}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Legend />
									<Bar dataKey="pv" fill="#8884d8" />
									<Bar dataKey="uv" fill="#82ca9d" />
								</BarChart>
							</Box> */}
						</Card>
					</Flex>
				</Fragment>
			)}
		</Flex>
	);
};

export default AdminPaymentPage;
