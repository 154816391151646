import { Box, Icon, Link, Stack } from "@chakra-ui/react";
import React, { Fragment, useContext } from "react";
import {
	FaBookOpen,
	FaBuildingUser,
	FaUser,
	FaUserTag,
	FaUsers,
} from "react-icons/fa6";
import { LuInspect } from "react-icons/lu";
import { MdAdminPanelSettings, MdApartment } from "react-icons/md";
import { RiReservedFill } from "react-icons/ri";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { Separator } from "../../components/Separator/Separator";
import SideBarLink from "./SideBarLink";
import { SidebarHelp } from "./SidebarHelp";

import GlobalContext from "../../Context";
import AfricartzLogo from "../../assets/logo.png";

const SidebarContent = ({ logoText }) => {
	const { isAdmin, profile } = useContext(GlobalContext);

	return (
		<>
			<Box pt={0} mb="1px">
				<Link
					href="/"
					target="_blank"
					display="flex"
					lineHeight="100%"
					mb="2px"
					fontWeight="bold"
					justifyContent="center"
					alignItems="center"
					fontSize="11px"
				>
					<img
						src={AfricartzLogo}
						w="28px"
						h="28px"
						me="5px"
						alt={logoText}
					/>
				</Link>
				<Separator />
			</Box>
			<Box overflowY="auto" maxHeight="calc(100vh - 70px)">
				<Stack direction="column" mb="1.5rem" mt="1.5rem">
					{isAdmin && (
						<Fragment>
							<SideBarLink
								text="Dashboard"
								icon={
									<Icon
										as={TbLayoutDashboardFilled}
										w={5}
										h={5}
									/>
								}
								route="admin/dashboard"
							/>
							<SideBarLink
								text="Bookings"
								icon={<Icon as={FaBookOpen} w={5} h={5} />}
								route="admin/bookings"
							/>
							<SideBarLink
								text="Apartments"
								icon={<Icon as={MdApartment} w={5} h={5} />}
								route="admin/apartments"
							/>
							<SideBarLink
								text="Landlords"
								icon={<Icon as={FaBuildingUser} w={5} h={5} />}
								route="admin/landlord"
							/>
							<SideBarLink
								text="Referrals"
								icon={<Icon as={FaUserTag} w={5} h={5} />}
								route="admin/referrer"
							/>
							<SideBarLink
								text="Reservation Request"
								icon={<Icon as={RiReservedFill} w={5} h={5} />}
								route="admin/reservation"
							/>
							<SideBarLink
								text="Inspection Request"
								icon={<Icon as={LuInspect} w={5} h={5} />}
								route="admin/inspection"
							/>
							<SideBarLink
								text="Users"
								icon={<Icon as={FaUsers} w={5} h={5} />}
								route="admin/users"
							/>

							{(window.location.hostname === "localhost" || profile?.role === "admin") && (
								<>
									<SideBarLink
										text="Staffs"
										icon={<Icon as={FaUser} w={5} h={5} />}
										route="admin/staffs"
									/>
									<SideBarLink
										text="Manual Dashboard"
										icon={
											<Icon
												as={MdAdminPanelSettings}
												w={5}
												h={5}
											/>
										}
										route="admin/manual"
									/>
								</>
							)}
						</Fragment>
					)}
				</Stack>
			</Box>
			<SidebarHelp />
		</>
	);
};

export default SidebarContent;
