import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useNotifier from "../../../hooks/useNotifier";
import { PostManualAPI } from "../../../Endpoints.jsx";
import GlobalContext from "../../../Context";
import { Button, FormLabel, Input } from "@chakra-ui/react";
import axios from 'axios';


function Upload() {
	const { token } = useContext(GlobalContext);
	const { id } = useParams();
	const navigate = useNavigate();
	const notify = useNotifier();

	const [loading, setLoading] = React.useState(false);

	const [file, setFile] = useState()

	function handleChange(event) {
		setFile(event.target.files[0])
	}

	function handleSubmit(event) {
		event.preventDefault()

		const formData = new FormData();
		formData.append('receipt', file);
		formData.append('request_id', id);

		const config = {
		  headers: {
			'Authorization': `Bearer ${token}`,
			'content-type': 'multipart/form-data',
		  },
		};

		axios.post(PostManualAPI, formData, config).then((_res) => {
		  	console.log(_res.data);
			if (_res?.data?.status === "success") {
				navigate(-1);
			} else {
				notify("Failed", _res?.message || "Try again", "error");
			}
		}).catch((_err) => {
			setLoading(false);
			notify("Failed", _err?.message || "Try again", "error");
		});
	}


	return (
		<div className="container">
			<form onSubmit={handleSubmit}>
				<Input
					display="none"
					id="logo"
					type="file"
					onChange={handleChange}
					required
				/>
				<FormLabel
					htmlFor="logo"
					disabled={loading}
					p={"8px 40px"}
					border="2px solid"
					fontSize="12px"
					borderColor="blue.300"
					color="blue.500"
					cursor={"pointer"}
					borderRadius={"100px"}
				>
					Add Receipt
				</FormLabel>

				{file && (
					<Button
						type="submit"
						isLoading={loading}
						fontSize="12px"
						fontWeight="medium"
						borderRadius={"100px"}
						px={6}
						w="max"
						mt={4}
						bg="green"
						_hover="none"
						_active={{
							bg: "white",
							transform: "none",
							borderColor: "transparent",
						}}
						_focus={{
							boxShadow: "none",
						}}
						color="white"
					>
						Submit Receipt
					</Button>
				)}
			</form>
		</div>
	);
}

export default Upload;
